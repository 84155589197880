'use strict';
app.factory("controlService", ['$rootScope', 'broadcastService', '$ocLazyLoad', 'managementService', '$timeout', 'excelExportService', 'displayService', function ($rootScope, broadcastService, $ocLazyLoad, managementService, $timeout, excelExportService, displayService)
{
    //invoke httpservice
    broadcastService.extendKendoDatasource();

    let controlServiceFactory = {};
    
    let _setPrintingFunction = function ($scope, requestedFormName, requestedReport, reportName, reportUrl, reportJsUrl, menuKey)
    {
        $scope.printThis = function ()
        {
            if ($scope.uniqueID != null)
            {
                managementService.requestForm({
                    requester: $scope.listenerName,
                    requesterID: $scope.uniqueID,
                    requestedForm: requestedFormName,
                    action: "create",
                    actionDetail: requestedReport, //Going to Change This To RequestReport + UniqueID
                    status: "pending",
                    signalCount: 0,
                    name: reportName,
                    url: reportUrl,
                    jsUrl: reportJsUrl,
                    MenuKey: menuKey
                });
            }
        }
    }

    let _requestFormFromFormInformation = function($scope, dataItem, enableNewUniqueIDIfNotFound = false) {
        const jsControllerUrl = _.get(dataItem, "JsControllerUrl");
        if (_.isEmpty(jsControllerUrl)) {
            alert("this form is not ready yet");
        } else {
            if (dataItem.ModuleName === 'Report' && dataItem.requestedForm === 'reportviewer') {
                _setPrintingWithParam(
                    $scope,
                    'reportviewer',
                    dataItem.MenuUrl,
                    dataItem.MenuName,
                    'master/views/reportviewer.html',
                    'master/controllers/reportviewerController.js',
                    {},
                    false,
                    dataItem.MenuKey,
                    enableNewUniqueIDIfNotFound
                );

                $scope.printThis({});
            } else {
                let action = "none";
                let actionDetail = "nil";

                if (typeof dataItem.Action != 'undefined') {
                    action = dataItem.Action;
                }

                if (typeof dataItem.ActionDetail != 'undefined') {
                    actionDetail = dataItem.ActionDetail;
                }

                //special handling for knowledge base form
                if (dataItem.MenuKey == 1060) {
                    action = "read";
                    actionDetail = "99_" + "index.html";
                }

                //special handling for user guide form
                if (dataItem.MenuKey == 708) {
                    action = "read";
                    actionDetail = "98_" + "index.html";
                }

                //added by Anny on 19/01/2017 to solve can't open two tab with same js file (eg: allowance, deduction reimbursement, recurrAllw or recurrDeduction)
                let data = {
                    requester: "userclick",
                    requesterID: null,
                    requestedForm: dataItem.MenuUrl.split("/")[dataItem.MenuUrl.split("/").length - 1].split(".html")[0],
                    action: action,
                    actionDetail: actionDetail,
                    status: "pending",
                    signalCount: 0,
                    name: dataItem.DispName,
                    url: dataItem.MenuUrl,
                    jsUrl: jsControllerUrl,
                    bookmarkData: dataItem,
                    MenuKey: dataItem.MenuKey,
                    icon: dataItem.Icon
                };

                //Note: Unable to control the existing report as the link is via userclick
                if (data.requestedForm === "reportviewer") {
                    data.action = "create";
                    data.actionDetail = 'Journal.trdx';
                    data.parameters = {TransHdrKey: dataItem.ActionDetail, Title: dataItem.DispName};
                    data.icon = dataItem.Icon;
                } else if (data.requestedForm === 'universal') {
                    data.action = "mockup";
                    data.actionDetail = {
                        moduleName: dataItem.ModuleName,
                        categoryName: data.name,
                        menuKey: dataItem.MenuKey
                    };
                    data.ModuleName = dataItem.ModuleName;
                    data.MenuKey = dataItem.MenuKey;
                    data.icon = dataItem.Icon;
                }

                //hack for custom view search
                if (dataItem.FormGroup === "Custom View")
                {
                    data.MenuKey = 1096;
                    data.parameters = _.cloneDeep(dataItem.MenuPara);
                }

                managementService.requestForm(data);
            }
        }
    }

    let _viewKnowledgeBase = function ($scope, knowledgebasepath) {
        var output = {};
        //to hack that it will always open the same form 
        output.param = "99_" + "HTML/" + knowledgebasepath;
        output.requestedForm = "knowledgebase";
        output.name = "Knowledge Base";
        output.url = "system/views/knowledgebase.html";
        output.jsUrl = "system/controllers/knowledgebaseController.js";
        output.MenuKey = 1060;

        managementService.requestForm({
            requester: $scope.listenerName,
            requesterID: $scope.uniqueID,
            requestedForm: output.requestedForm,
            action: "read",
            actionDetail: output.param,
            status: "pending",
            signalCount: 0,
            name: output.name,
            url: output.url,
            MenuKey: output.MenuKey,
            jsUrl: output.jsUrl
        });
    }

    let _setPrintingWithParam = function ($scope, requestedFormName, requestedReport, reportName, reportUrl, reportJsUrl,
        reportParam, createNew, menuKey, enableNewUniqueIDIfNotFound = false)
    {
        $scope.printThis = function (reportParam, refreshReport)
        {
            let finalUniqueID = $scope.uniqueID;
            if (!finalUniqueID && enableNewUniqueIDIfNotFound) finalUniqueID = broadcastService.guid();
            if (!finalUniqueID) return;

            managementService.requestForm({
                requester: $scope.listenerName,
                requesterID: finalUniqueID,
                requestedForm: requestedFormName,
                action: "create",
                actionDetail: requestedReport,
                parameters: reportParam,
                status: "pending",
                signalCount: 0,
                name: reportName,
                url: reportUrl,
                jsUrl: reportJsUrl,
                refresh: refreshReport, //For Report Only
                MenuKey: menuKey
            });
        }
    }

    let _checkLimit = function ()
    {
        return true;
    }

    let _scrollTop = function ()
    {
        $('html,body').animate({ scrollTop: $($(event.target).parents(".tabContent").find(".middleRegion")).offset().top - $("nav").height() }, "fast");
        $("#tabstrip > div.k-content.k-state-active.tabstrip-content").animate({ scrollTop: $($(event.target).parents(".tabContent").find(".middleRegion")).offset().top }, "fast");
    }

    let _backToTop = function ()
    {
        $('html,body').animate({ scrollTop: 0 }, "fast");
        $("#tabstrip > div.k-content.k-state-active.tabstrip-content").animate({ scrollTop: 0 }, "fast");
    }

    /* Parsing URLs in JavaScript */
    //https://www.abeautifulsite.net/parsing-urls-in-javascript
    let _parseUrl = function (url)
    {
        url = typeof (url) == "function" ? url() : url;

        let parser = document.createElement('a'),
            searchObject = {},
            queries, split, i;

        // Let the browser do the work
        parser.href = url;
        // Convert query string to object

        if (parser.search)
        {    //if search string is not empty
            queries = parser.search.replace(/^\?/, '').split('&');
            for (i = 0; i < queries.length; i++)
            {
                split = queries[i].split('=');
                searchObject[split[0]] = split[1];
            }
        }
        return {
            protocol: parser.protocol,
            host: parser.host,
            hostname: parser.hostname,
            port: parser.port,
            pathname: parser.pathname,
            search: parser.search,
            searchObject: searchObject,
            hash: parser.hash
        };
    }

    let _sendEncryptedDataToServer = function (encryptedData)
    {

    };

    /* Allow dashboard or anyone use setFullScreen function - 04/02/2015 */
    let _setFullScreen = function setFullScreen($event)
    {
        if (!$($event.target.parentElement.parentElement).hasClass("fullscreen"))
        {
            $($event.target.parentElement.parentElement).addClass("fullscreen");
        }
        else
        {
            $($event.target.parentElement.parentElement).removeClass("fullscreen");
        }
    }

    let _numericTextboxEditor = function (container, options)
    {
        return _numericTextbox.apply(this, [].slice.call(arguments));
    }

    let _numericTextboxMaxlength1Editor = function (container, options)
    {
        options ? options.maxlength = 1 : container.maxlength = 1;
        return _numericTextbox.apply(this, [].slice.call(arguments));
    }

    let _numericTextboxMaxlength6Editor = function (container, options)
    {
        options ? options.maxlength = 6 : container.maxlength = 6;
        return _numericTextbox.apply(this, [].slice.call(arguments));
    }

    let _numericTextboxMaxlength7Editor = function (container, options)
    {
        options ? options.maxlength = 7 : container.maxlength = 7;
        return _numericTextbox.apply(this, [].slice.call(arguments));
    }

    let _numericTextboxMaxlength14Editor = function (container, options)
    {
        options ? options.maxlength = 14 : container.maxlength = 14;
        return _numericTextbox.apply(this, [].slice.call(arguments));
    }

    let _numericTextboxMaxlength14AllowLessThan0Editor = function (container, options)
    {
        return _numericTextboxAllowLessThan0Editor.apply(this, [].slice.call(arguments).concat(14));
    }

    let _numericTextboxMaxlength14AllowNullEditor = function (container, options) 
    {
        return _numericTextboxAllowNullEditor.apply(this, [].slice.call(arguments).concat(14));
    }

    let _numericTextbox = function (configuration, options)
    {

        let config = {
            min: 0,
            spinners: false,
            step: 0,
            maxlength: 14
        };

        if (options !== undefined)
        {
            if (options.format !== undefined)
            {
                if (options.format.indexOf('n') > -1 && options.format.indexOf("}") > -1 && options.format.indexOf("}") > options.format.indexOf('n'))
                {
                    config.decimals = parseInt(options.format.substring(options.format.indexOf('n') + 1, options.format.indexOf("}")).trim());
                }
            }
        }

        let f = function (container, options)
        {
            let editor = $('<input data-bind="value:' + options.field + '" maxlength="' + options.maxlength + '" style="text-align:right;" />')
                .appendTo(container)
                .kendoNumericTextBox(config)
                .bind('change', function ()
                {
                    if (this.value == '' || this.value == null || this.value === 'undefined')
                    {
                        this.value = 0;
                    }
                });
        }

        if (configuration.data)
        {
            //arguments from Kendo editor
            f = f.apply(this, arguments);
        }
        else
        {
            //arguments from function parameter
            Object.keys(configuration).forEach(function (key)
            {
                config[key] = configuration[key];
            })
        }

        return f;
    }

    let _numericTextboxAllowLessThan0Editor = function (configuration, options)
    {

        let maxlength = arguments[arguments.length - 1];

        let config = {
            spinners: false,
            step: 0
        };

        if (options !== undefined)
        {
            if (options.format !== undefined)
            {
                if (options.format.indexOf('n') > -1 && options.format.indexOf("}") > -1 && options.format.indexOf("}") > options.format.indexOf('n'))
                {
                    config.decimals = parseInt(options.format.substring(options.format.indexOf('n') + 1, options.format.indexOf("}")).trim());
                }
            }
        }

        let f = function (container, options)
        {
            let editor = $('<input data-bind="value:' + options.field + '" maxlength="' + maxlength + '" style="text-align:right;" />')
                .appendTo(container)
                .kendoNumericTextBox(config)
                .bind('change', function ()
                {
                    if (this.value == '' || this.value == null || this.value === 'undefined')
                    {
                        this.value = 0;
                    }
                });
        }

        if (configuration.data)
        {
            //arguments from Kendo editor
            f = f.apply(this, arguments);
        }
        else
        {
            //arguments from function parameter
            Object.keys(configuration).forEach(function (key)
            {
                config[key] = configuration[key];
            })
        }

        return f;
    }

    let _numericTextboxAllowNullEditor = function (configuration, options) {

        let maxlength = arguments[arguments.length - 1];

        let config = {
            spinners: false,
            step: 0
        };

        if (options !== undefined) {
            if (options.format !== undefined) {
                if (options.format.indexOf('n') > -1 && options.format.indexOf("}") > -1 && options.format.indexOf("}") > options.format.indexOf('n')) {
                    config.decimals = parseInt(options.format.substring(options.format.indexOf('n') + 1, options.format.indexOf("}")).trim());
                }
            }
        }

        let f = function (container, options) {
            let editor = $('<input data-bind="value:' + options.field + '" maxlength="' + maxlength + '" style="text-align:right;" />')
                .appendTo(container)
                .kendoNumericTextBox(config)
                .bind('change', function () {
                    if (this.value == '' || this.value == null || this.value === 'undefined') {
                        this.value = null;
                    }
                });
        }

        if (configuration.data) {
            //arguments from Kendo editor
            f = f.apply(this, arguments);
        }
        else {
            //arguments from function parameter
            Object.keys(configuration).forEach(function (key) {
                config[key] = configuration[key];
            })
        }

        return f;
    }

    let _clearCustomFilter = function (originalFilter)
    {
        if (originalFilter !== undefined)
        {
            if (originalFilter.filters !== undefined)
            {
                for (var i = 0; i < originalFilter.filters.length; i++)
                {
                    if (originalFilter.filters[i].CustomFlag === undefined)
                    {
                        if (originalFilter.filters[i].filters !== undefined)
                        {
                            _clearCustomFilter(originalFilter.filters[i]);
                        }
                    }
                    else
                    {
                        originalFilter.filters.splice(i, 1);
                        i = i - 1;
                    }
                }
            }
        }
    }

    let _clearUselessLogic = function (originalFilter)
    {
        if (originalFilter !== undefined)
        {
            if (originalFilter.filters !== undefined)
            {
                if (originalFilter.filters.length == 1)
                {
                    //join it
                    originalFilter = originalFilter.filters[0];

                    if (originalFilter.filters !== undefined)
                    {
                        _clearUselessLogic(originalFilter);
                    }
                }
                else if (originalFilter.filters.length > 1)
                {
                    for (var i = 0; i < originalFilter.filters.length; i++)
                    {
                        _clearUselessLogic(originalFilter.filters[i]);
                    }
                }
            }
        }

        return originalFilter;
    }

    let _cleanFilter = function (originalFilter)
    {
        let _filter = _.cloneDeep(originalFilter);
        _clearCustomFilter(_filter);

        //temporary fix, need to fix the stupid nested loop in long run
        _filter = _clearUselessLogic(_filter);
        _filter = _clearUselessLogic(_filter);

        if (_filter != undefined)
        {
            if (_filter.operator != undefined)
            {
                //means cleared too much dy
                let tmp = { filters: [], logic: "and" };
                tmp.filters[0] = _filter;

                _filter = tmp;
            }
        }

        return _filter;
    }

    let _pushCustomFilter = function (type, originalFilter)
    {
        let filter = _.cloneDeep(originalFilter);

        if (type === "Active")
        {
            if (!filter)
            {
                filter = {
                    filters: [{
                        field: "Active",
                        operator: "eq",
                        value: true,
                        CustomFlag: true
                    }],
                    logic: "and"
                };
            }
            else
            {
                if (JSON.stringify(filter).indexOf('{"field":"Active","operator":"eq","value":true,"CustomFlag":true}') <= -1)
                {
                    if (filter.logic != "and")
                    {
                        filter = { filters: [filter], logic: "and" };
                    }

                    filter.filters.push({
                        field: "Active",
                        operator: "eq",
                        value: true,
                        CustomFlag: true
                    });
                }
            }
        }
        else if (type === "CtrlAcc")
        {
            if (!filter)
            {
                filter = {
                    filters: [{
                        field: "IsControlAccount",
                        operator: "eq",
                        value: false,
                        CustomFlag: true
                    }],
                    logic: "and"
                };
            }
            else
            {
                if (JSON.stringify(filter).indexOf('{"field":"IsControlAccount","operator":"eq","value":false,"CustomFlag":true}') <= -1)
                {
                    if (filter.logic != "and")
                    {
                        filter = { filters: [filter], logic: "and" };
                    }

                    filter.filters.push({
                        field: "IsControlAccount",
                        operator: "eq",
                        value: false,
                        CustomFlag: true
                    });
                }
            }
        }
        else if (type === "Status")
        {
            if (!filter)
            {
                filter = {
                    filters: [{
                        field: "Status",
                        operator: function (item, value)
                        {
                            if (typeof item !== "undefined")
                            {
                                if ($.inArray(item.toString(), ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) > -1)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            }
                            else
                            {
                                return true;
                            }
                        },
                        value: -5201314,
                        CustomFlag: true
                    }],
                    logic: "and"
                };
            }
            else
            {
                if (JSON.stringify(filter).indexOf('"value":-5201314,"CustomFlag":true}') <= -1)
                {
                    if (filter.logic != "and")
                    {
                        filter = { filters: [filter], logic: "and" };
                    }

                    filter.filters.push({
                        field: "Status",
                        operator: function (item, value)
                        {
                            if (typeof item !== "undefined")
                            {
                                if ($.inArray(item.toString(), ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) > -1)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            }
                            else
                            {
                                return true;
                            }
                        },
                        value: -5201314,
                        CustomFlag: true
                    });
                }
            }
        }
        else if (type === "StatusID")
        {
            if (!filter)
            {
                filter = {
                    filters: [{
                        field: "StatusID",
                        operator: function (item, value)
                        {
                            if (typeof item !== "undefined")
                            {
                                if ($.inArray(item.toString(), ["11", 11]) > -1)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            }
                            else
                            {
                                return true;
                            }
                        },
                        value: -5201314,
                        CustomFlag: true
                    }],
                    logic: "and"
                };
            }
            else
            {
                if (JSON.stringify(filter).indexOf('"value":-5201314,"CustomFlag":true}') <= -1)
                {
                    if (filter.logic != "and")
                    {
                        filter = { filters: [filter], logic: "and" };
                    }

                    filter.filters.push({
                        field: "StatusID",
                        operator: function (item, value)
                        {
                            if (typeof item !== "undefined")
                            {
                                if ($.inArray(item.toString(), ["11", 11]) > -1)
                                {
                                    return false;
                                }
                                else
                                {
                                    return true;
                                }
                            }
                            else
                            {
                                return true;
                            }
                        },
                        value: -5201314,
                        CustomFlag: true
                    });
                }
            }
        }

        return filter;
    }

    let _parseKendoFilter = function (currentValue, index, filter)
    {
        //no filter
        if (typeof filter === "undefined")
        {
            return true;
        }
        else if (typeof currentValue !== "undefined" && currentValue !== null)
        {
            if (typeof filter.logic !== "undefined" && filter.logic !== null)
            {
                if (filter.logic === "and")
                {
                    for (var i = 0; i < filter.filters.length; i++)
                    {
                        if (typeof filter.filters[i].logic === "undefined")
                        {
                            if (filter.filters[i].operator === "neq")
                            {
                                if (filter.filters[i].ignoreCase === true)
                                {
                                    if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                    {
                                        if (currentValue[filter.filters[i].field].toLowerCase() === filter.filters[i].value.toLowerCase())
                                        {
                                            return false;
                                        }
                                    }
                                    else if (typeof currentValue[filter.filters[i].field] === "string" || typeof filter.filters[i].value === "string")
                                    {
                                        if (currentValue[filter.filters[i].field].toString().toLowerCase() === filter.filters[i].value.toString().toLowerCase())
                                        {
                                            return false;
                                        }
                                    }
                                    else
                                    {
                                        if (currentValue[filter.filters[i].field] == filter.filters[i].value)
                                        {
                                            return false;
                                        }
                                    }
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] == filter.filters[i].value)
                                    {
                                        return false;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "eq")
                            {
                                if (filter.filters[i].ignoreCase === true)
                                {
                                    if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                    {
                                        if (currentValue[filter.filters[i].field].toLowerCase() !== filter.filters[i].value.toLowerCase())
                                        {
                                            return false;
                                        }
                                    }
                                    else if (typeof currentValue[filter.filters[i].field] === "string" || typeof filter.filters[i].value === "string")
                                    {
                                        if (currentValue[filter.filters[i].field].toString().toLowerCase() !== filter.filters[i].value.toString().toLowerCase())
                                        {
                                            return false;
                                        }
                                    }
                                    else
                                    {
                                        if (currentValue[filter.filters[i].field] != filter.filters[i].value)
                                        {
                                            return false;
                                        }
                                    }
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] != filter.filters[i].value)
                                    {
                                        return false;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "isnull")
                            {
                                if (currentValue[filter.filters[i].field] !== null)
                                {
                                    return false;
                                }
                            }
                            else if (filter.filters[i].operator === "isnotnull")
                            {
                                if (currentValue[filter.filters[i].field] === null)
                                {
                                    return false;
                                }
                            }
                            else if (filter.filters[i].operator === "lt")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== typeof filter.filters[i].value)
                                {
                                    return false;
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] >= filter.filters[i].value)
                                    {
                                        return false;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "lte")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== typeof filter.filters[i].value)
                                {
                                    return false;
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] > filter.filters[i].value)
                                    {
                                        return false;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "gt")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== typeof filter.filters[i].value)
                                {
                                    return false;
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] <= filter.filters[i].value)
                                    {
                                        return false;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "gte")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== typeof filter.filters[i].value)
                                {
                                    return false;
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] < filter.filters[i].value)
                                    {
                                        return false;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "startswith")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== "string" || typeof filter.filters[i].value !== "string")
                                {
                                    return false;
                                }
                                else
                                {
                                    if (filter.filters[i].ignoreCase === true)
                                    {
                                        if (!currentValue[filter.filters[i].field].toLowerCase().startsWith(filter.filters[i].value.toLowerCase()))
                                        {
                                            return false;
                                        }
                                    }
                                    else
                                    {
                                        if (!currentValue[filter.filters[i].field].startsWith(filter.filters[i].value))
                                        {
                                            return false;
                                        }
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "endswith")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== "string" || typeof filter.filters[i].value !== "string")
                                {
                                    return false;
                                }
                                else
                                {
                                    if (filter.filters[i].ignoreCase === true)
                                    {
                                        if (!currentValue[filter.filters[i].field].toLowerCase().endsWith(filter.filters[i].value.toLowerCase()))
                                        {
                                            return false;
                                        }
                                    }
                                    else
                                    {
                                        if (!currentValue[filter.filters[i].field].endsWith(filter.filters[i].value))
                                        {
                                            return false;
                                        }
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "contains")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== "string" || typeof filter.filters[i].value !== "string")
                                {
                                    return false;
                                }
                                else
                                {
                                    if (filter.filters[i].ignoreCase === true)
                                    {
                                        if (!currentValue[filter.filters[i].field].toLowerCase().includes(filter.filters[i].value.toLowerCase()))
                                        {
                                            return false;
                                        }
                                    }
                                    else
                                    {
                                        if (!currentValue[filter.filters[i].field].includes(filter.filters[i].value))
                                        {
                                            return false;
                                        }
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "doesnotcontain")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== "string" || typeof filter.filters[i].value !== "string")
                                {
                                    return false;
                                }
                                else
                                {
                                    if (filter.filters[i].ignoreCase === true)
                                    {
                                        if (currentValue[filter.filters[i].field].toLowerCase().includes(filter.filters[i].value.toLowerCase()))
                                        {
                                            return false;
                                        }
                                    }
                                    else
                                    {
                                        if (currentValue[filter.filters[i].field].includes(filter.filters[i].value))
                                        {
                                            return false;
                                        }
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "isempty")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== "string" || typeof filter.filters[i].value !== "string")
                                {
                                    return false;
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] !== "")
                                    {
                                        return false;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "isnotempty")
                            {
                                if (typeof currentValue[filter.filters[i].field] !== "string" || typeof filter.filters[i].value !== "string")
                                {
                                    return false;
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] === "")
                                    {
                                        return false;
                                    }
                                }
                            }
                            else if (typeof filter.filters[i].operator === "function")
                            {
                                if (!filter.filters[i].operator(currentValue[filter.filters[i].field], filter.filters[i].value))
                                {
                                    return false;
                                }
                            }
                            else
                            {
                                return false;
                            }
                        }
                        else
                        {
                            if (!_parseKendoFilter(currentValue, index, filter.filters[i]))
                            {
                                return false;
                            }
                        }
                    }

                    return true;
                }
                else if (filter.logic === "or")
                {
                    for (var i = 0; i < filter.filters.length; i++)
                    {
                        if (typeof filter.filters[i].logic === "undefined")
                        {
                            if (filter.filters[i].operator === "neq")
                            {
                                if (filter.filters[i].ignoreCase === true)
                                {
                                    if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                    {
                                        if (currentValue[filter.filters[i].field].toLowerCase() !== filter.filters[i].value.toLowerCase())
                                        {
                                            return true;
                                        }
                                    }
                                    else if (typeof currentValue[filter.filters[i].field] === "string" || typeof filter.filters[i].value === "string")
                                    {
                                        if (currentValue[filter.filters[i].field].toString().toLowerCase() !== filter.filters[i].value.toString().toLowerCase())
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {
                                        if (currentValue[filter.filters[i].field] != filter.filters[i].value)
                                        {
                                            return true;
                                        }
                                    }
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] != filter.filters[i].value)
                                    {
                                        return true;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "eq")
                            {
                                if (filter.filters[i].ignoreCase === true)
                                {
                                    if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                    {
                                        if (currentValue[filter.filters[i].field].toLowerCase() === filter.filters[i].value.toLowerCase())
                                        {
                                            return true;
                                        }
                                    }
                                    else if (typeof currentValue[filter.filters[i].field] === "string" || typeof filter.filters[i].value === "string")
                                    {
                                        if (currentValue[filter.filters[i].field].toString().toLowerCase() === filter.filters[i].value.toString().toLowerCase())
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {
                                        if (currentValue[filter.filters[i].field] == filter.filters[i].value)
                                        {
                                            return true;
                                        }
                                    }
                                }
                                else
                                {
                                    if (currentValue[filter.filters[i].field] == filter.filters[i].value)
                                    {
                                        return true;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "isnull")
                            {
                                if (currentValue[filter.filters[i].field] === null)
                                {
                                    return true;
                                }
                            }
                            else if (filter.filters[i].operator === "isnotnull")
                            {
                                if (currentValue[filter.filters[i].field] !== null)
                                {
                                    return true;
                                }
                            }
                            else if (filter.filters[i].operator === "lt")
                            {
                                if (typeof currentValue[filter.filters[i].field] === typeof filter.filters[i].value)
                                {
                                    if (currentValue[filter.filters[i].field] < filter.filters[i].value)
                                    {
                                        return true;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "lte")
                            {
                                if (typeof currentValue[filter.filters[i].field] === typeof filter.filters[i].value)
                                {
                                    if (currentValue[filter.filters[i].field] <= filter.filters[i].value)
                                    {
                                        return true;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "gt")
                            {
                                if (typeof currentValue[filter.filters[i].field] === typeof filter.filters[i].value)
                                {
                                    if (currentValue[filter.filters[i].field] > filter.filters[i].value)
                                    {
                                        return true;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "gte")
                            {
                                if (typeof currentValue[filter.filters[i].field] === typeof filter.filters[i].value)
                                {
                                    if (currentValue[filter.filters[i].field] >= filter.filters[i].value)
                                    {
                                        return true;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "startswith")
                            {
                                if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                {
                                    if (filter.filters[i].ignoreCase === true)
                                    {
                                        if (currentValue[filter.filters[i].field].toLowerCase().startsWith(filter.filters[i].value.toLowerCase()))
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {
                                        if (currentValue[filter.filters[i].field].startsWith(filter.filters[i].value))
                                        {
                                            return true;
                                        }
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "endswith")
                            {
                                if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                {
                                    if (filter.filters[i].ignoreCase === true)
                                    {
                                        if (currentValue[filter.filters[i].field].toLowerCase().endsWith(filter.filters[i].value.toLowerCase()))
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {
                                        if (currentValue[filter.filters[i].field].endsWith(filter.filters[i].value))
                                        {
                                            return true;
                                        }
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "contains")
                            {
                                if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                {
                                    if (filter.filters[i].ignoreCase === true)
                                    {
                                        if (currentValue[filter.filters[i].field].toLowerCase().includes(filter.filters[i].value.toLowerCase()))
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {
                                        if (currentValue[filter.filters[i].field].includes(filter.filters[i].value))
                                        {
                                            return true;
                                        }
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "doesnotcontain")
                            {
                                if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                {
                                    if (filter.filters[i].ignoreCase === true)
                                    {
                                        if (!currentValue[filter.filters[i].field].toLowerCase().includes(filter.filters[i].value.toLowerCase()))
                                        {
                                            return true;
                                        }
                                    }
                                    else
                                    {
                                        if (!currentValue[filter.filters[i].field].includes(filter.filters[i].value))
                                        {
                                            return true;
                                        }
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "isempty")
                            {
                                if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                {
                                    if (currentValue[filter.filters[i].field] === "")
                                    {
                                        return true;
                                    }
                                }
                            }
                            else if (filter.filters[i].operator === "isnotempty")
                            {
                                if (typeof currentValue[filter.filters[i].field] === "string" && typeof filter.filters[i].value === "string")
                                {
                                    if (currentValue[filter.filters[i].field] !== "")
                                    {
                                        return true;
                                    }
                                }
                            }
                            else if (typeof filter.filters[i].operator === "function")
                            {
                                if (filter.filters[i].operator(currentValue[filter.filters[i].field], filter.filters[i].value))
                                {
                                    return true;
                                }
                            }
                            else
                            {
                                return false;
                            }
                        }
                        else
                        {
                            if (_parseKendoFilter(currentValue, index, filter.filters[i]))
                            {
                                return true;
                            }
                        }
                    }

                    return false;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return false
            }
        }
        else
        {
            return false
        }
    };

    let _findMissingExpecteddataItem = function (dataTextField, dataValueField, originalFilter, tmpFilteringDS, currentComboboxText, currentComboboxValue, id, breaker)
    {
        //time to get creative since dataText indicates that there is selected item however the dataItem indicates blank selection
        let ExpectedItem = {};

        //scenario
        //1. filter original data with original filter to get combobox current's view
        //2. find by text assuming dataTextField and comboboxtext is same field
        //3. find by value assuming dataValueField and comboboxvalue is same field
        //4. if still failes, then there are changes that current expected item is being filtered off by original data
        //4a. get original data and perform 2,3 again.


        //first, get filtered datasource by original filter
        let item = [];

        if (breaker === true)
        {
            item = tmpFilteringDS;
        }
        else
        {
            item = tmpFilteringDS.filter(function (vax, indx)
            {
                return _parseKendoFilter(vax, indx, originalFilter);
            });
        }

        //second, need to get correct row in item based on wrongly assigned currentComboboxText and currentComboboxValue
        let itemfindbyText = item.find(function (currentValue)
        {
            if (typeof currentValue !== "undefined" && currentValue !== null)
                return currentValue[dataTextField].toString() === currentComboboxText;

            return false;
        });

        //if found, use that as ExpectedItem and update currentComboboxText and currentComboboxValue
        if (typeof itemfindbyText !== "undefined")
        {
            ExpectedItem = _.cloneDeep(itemfindbyText);
            ExpectedItem["id"] = itemfindbyText[id];
            ExpectedItem["idField"] = id;
        }
        else
        {
            //third, if text not found then find by value
            let itemfindbyValue = item.find(function (currentValue)
            {
                if (typeof currentValue !== "undefined" && currentValue !== null)
                    return currentValue[dataValueField].toString() === currentComboboxValue;

                return false;
            });

            //if found, use as ExpectedItem and update currentComboboxText and currentComboboxValue
            if (typeof itemfindbyValue !== "undefined")
            {
                ExpectedItem = _.cloneDeep(itemfindbyValue);
                ExpectedItem["id"] = itemfindbyValue[id];
                ExpectedItem["idField"] = id;
            }
            else
            {
                if (breaker !== true)
                {
                    //fourth, use tmpFilteringDS to repeat all again
                    ExpectedItem = _findMissingExpecteddataItem(dataTextField, dataValueField, {}, tmpFilteringDS, currentComboboxText, currentComboboxValue, id, true);
                }
            }
        }

        if (JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
        {
            return ExpectedItem;
        }
        else
        {
            return {};
        }
    };

    let _customFlagRemover = function (filter)
    {
        if (filter !== undefined)
        {
            if (filter.filters !== undefined)
            {
                for (var i = 0; i < filter.filters.length; i++)
                {
                    if (filter.filters[i].CustomFlag === undefined)
                    {
                        if (filter.filters[i].filters !== undefined)
                        {
                            _customFlagRemover(filter.filters[i]);
                        }
                    }
                    else
                    {
                        delete filter.filters[i].CustomFlag;
                    }
                }
            }
        }

        return filter;
    }

    let _compareFilter = function (source, target)
    {
        if (typeof source !== "undefined" && source != null && typeof target !== "undefined" && target != null)
        {
            let _source = _.cloneDeep(source);
            let _target = _.cloneDeep(target);

            return JSON.stringify(_customFlagRemover(_source)) == JSON.stringify(_customFlagRemover(_target));
        }
        else
        {
            return JSON.stringify(source) == JSON.stringify(target);
        }
    }

    //For Kendo combo box open event
    let _comboBoxOpenEvent = function (e, filterCol, passThis)
    {
        if (typeof e == "undefined"
            || typeof e.sender == "undefined"
            || typeof e.sender.dataSource == "undefined"
            || e.sender.dataSource.data().length == 0
            || typeof e.sender.dataSource.options == "undefined"
            || typeof e.sender.dataSource.options.schema == "undefined"
            || typeof e.sender.dataSource.options.schema.model == "undefined"
            || typeof e.sender.dataSource.options.schema.model.id == "undefined")
        {
            return;
        }

        //clean out the filters
        let originalFilter = _cleanFilter(e.sender.dataSource._filter);

        //clean all tag!!!
        let cachelengthMain = e.sender.dataSource._data.length;
        for (var i = 0; i < cachelengthMain; i++)
        {
            if (e.sender.dataSource._data[i].setInactive !== undefined)
            {
                delete e.sender.dataSource._data[i].setInactive;
                //break;
            }
        }

        let currentComboboxValue = e.sender.value();
        let currentComboboxText = e.sender.text();

        let tmpFilteringDS = e.sender.dataSource.data().toJSON();
        let ExpectedItem = {};

        //means in correct event, not clear text
        //always start to find value from text only
        if (currentComboboxText != "")
        {
            //first, find the correct row and set the correct combobox value and text
            if (e.sender.dataItem() !== null && e.sender.dataItem() !== undefined && typeof e.sender.dataItem() !== "undefined")
            {
                ExpectedItem = _.cloneDeep(e.sender.dataItem());
            }
            else
            {
                //attempt to find missing row
                ExpectedItem = _findMissingExpecteddataItem(e.sender.options.dataTextField, e.sender.options.dataValueField, _.cloneDeep(originalFilter), tmpFilteringDS, currentComboboxText, currentComboboxValue, e.sender.dataSource.options.schema.model.id);
            }

            if (JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
            {
                if (currentComboboxValue.toString() !== ExpectedItem["id"].toString())
                    currentComboboxValue = ExpectedItem["id"];

                if (currentComboboxText.toString() !== ExpectedItem[e.sender.options.dataTextField].toString())
                    currentComboboxText = ExpectedItem[e.sender.options.dataTextField];
            }
            else
            {
                //last, assume nothing is selected
                currentComboboxText = "";
                currentComboboxValue = "";
            }
        }
        else
        {
            //text is empty, indicating that combo box is being cleared, should clear currentComboboxValue
            currentComboboxValue = "";
        }

        if (e.sender.dataSource.data()[0].Active !== undefined)
        {
            originalFilter = _pushCustomFilter("Active", originalFilter);
        }

        if (e.sender.dataSource.data()[0].IsControlAccount !== undefined)
        {
            originalFilter = _pushCustomFilter("CtrlAcc", originalFilter);
        }

        if (e.sender.dataSource.data()[0].Status !== undefined)
        {
            originalFilter = _pushCustomFilter("Status", originalFilter);
        }

        if (e.sender.dataSource.data()[0].StatusID !== undefined)
        {
            originalFilter = _pushCustomFilter("StatusID", originalFilter);
        }
        
        //append only when you are sure that current combobox got value and found selected row
        if (typeof currentComboboxValue !== "undefined" && currentComboboxValue !== "" && currentComboboxValue !== null && e.sender._state !== "filter" && JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
        {
            //append back if expecteditem is being filtered off by default filter
            if (ExpectedItem["Active"] === false || ExpectedItem["IsControlAccount"] === true || ($.inArray(ExpectedItem["Status"], ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) > -1) || ($.inArray(ExpectedItem["StatusID"], ["11", 11]) > -1))
            {
                originalFilter = { filters: [originalFilter], logic: "or" };
                originalFilter.filters.push({
                    field: ExpectedItem["idField"].toString(),
                    operator: "eq",
                    value: ExpectedItem["id"],
                    CustomFlag: true
                });
            }
            else
            {
                //check if expected item being filtered off by form's custom filter
                let item = tmpFilteringDS.filter(function (vax, indx)
                {
                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                });

                item = item.find(function (currentValue)
                {
                    if (typeof currentValue !== "undefined" && currentValue !== null)
                        return currentValue[ExpectedItem["idField"].toString()].toString() === ExpectedItem["id"].toString();

                    return false;
                });

                //if doesn't exist in view means kena filtered off, append it back
                if (typeof item === "undefined")
                {
                    originalFilter = { filters: [originalFilter], logic: "or" };
                    originalFilter.filters.push({
                        field: ExpectedItem["idField"].toString(),
                        operator: "eq",
                        value: ExpectedItem["id"],
                        CustomFlag: true
                    });

                    //tag this line!
                    let cacheLength = e.sender.dataSource._data.length;
                    for (var i = 0; i < cacheLength; i++)
                    {
                        let currentitem = e.sender.dataSource._data[i][ExpectedItem["idField"].toString()];

                        if (typeof currentitem !== "undefined" && currentitem !== null)
                        {
                            if (currentitem.toString() === ExpectedItem["id"].toString())
                            {
                                e.sender.dataSource._data[i].setInactive = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
        
        //Start filtering data.
        if (currentComboboxText != "" && currentComboboxValue != "")
        {
            if (e.sender.dataSource.pageSize() !== undefined)
            {
                //apply filter
                let item = tmpFilteringDS.filter(function (vax, indx)
                {
                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                });

                if (typeof e.sender.dataSource.sort() !== "undefined")
                {
                    let sortArray = [];

                    if (typeof e.sender.dataSource.sort().length === "undefined")
                    {
                        if (e.sender.dataSource.sort().dir === "desc")
                        {
                            sortArray.push("-" + e.sender.dataSource.sort().field);
                        }
                        else
                        {
                            sortArray.push(e.sender.dataSource.sort().field);
                        }
                    }
                    else
                    {
                        for (var j = 0; j < e.sender.dataSource.sort().length; j++)
                        {
                            if (e.sender.dataSource.sort()[j].dir === "desc")
                            {
                                sortArray.push("-" + e.sender.dataSource.sort()[j].field);
                            }
                            else
                            {
                                sortArray.push(e.sender.dataSource.sort()[j].field);
                            }
                        }
                    }

                    switch (sortArray.length)
                    {
                        case 0:
                            break;
                        case 1:
                            item = item.sortBy(sortArray[0]);
                            break;
                        case 2:
                            item = item.sortBy(sortArray[0], sortArray[1]);
                            break;
                        case 3:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2]);
                            break;
                        case 4:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3]);
                            break;
                        case 5:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4]);
                            break;
                        case 6:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5]);
                            break;
                        case 7:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6]);
                            break;
                        case 8:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7]);
                            break;
                        case 9:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7], sortArray[8]);
                            break;
                        case 10:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7], sortArray[8], sortArray[9]);
                            break;
                        default:
                            break;
                    }
                }

                let TempPageSize = e.sender.dataSource.pageSize();

                let ViewIndex = item.findIndex(function (vax)
                {
                    if (typeof vax !== "undefined" && vax !== null)
                    {
                        if (typeof vax[ExpectedItem["idField"].toString()] !== "undefined" && vax[ExpectedItem["idField"].toString()] !== null)
                        {
                            if (vax[ExpectedItem["idField"].toString()].toString() === ExpectedItem["id"].toString())
                            {
                                return true;
                            }
                        }
                    }

                    return false;
                });

                let startIndexTemp = (ViewIndex < 0) ? 0 : (ViewIndex.Nminus(TempPageSize.Ndiv(2)).NtoDP(0));
                startIndexTemp = (startIndexTemp < 0) ? 0 : startIndexTemp;

                let filter = _.cloneDeep(originalFilter);
                if (!filter)
                {
                    filter = { filters: [], logic: "and" };
                }
                else
                {
                    if (filter.logic != "and")
                    {
                        filter = { filters: [filter], logic: "and" };
                    }
                };

                let filterPageSize = { filters: [], logic: "or" };

                let filterColumn = e.sender.options.dataTextField;
                if (filterCol != undefined)
                {
                    filterColumn = filterCol;
                }

                if (item.length > TempPageSize)
                {
                    let isSorted = true;

                    if (item.length > 100)
                    {
                        //assume sorted, can't handle, let the form handle itself
                        isSorted = true;
                    }
                    else
                    {
                        for (var i = startIndexTemp; i < startIndexTemp + TempPageSize; i++)
                        {
                            if (i < item.length - 1)
                            {
                                if (item[i][filterColumn] > item[i + 1][filterColumn])
                                {
                                    isSorted = false;
                                    break;
                                }
                            }
                            else
                            {
                                break;
                            }
                        }
                    }

                    //page only when view > 1
                    if (!isSorted)
                    {
                        for (var i = startIndexTemp; i < startIndexTemp + TempPageSize; i++)
                        {
                            if (i < item.length && item.length > 1)
                            {
                                filterPageSize.filters[filterPageSize.filters.length] = { field: filterColumn, operator: "eq", value: item[i][filterColumn] };
                            }
                            else
                            {
                                break;
                            }
                        }

                        if (filterPageSize.filters.length > 0)
                        {
                            filter.filters.push(filterPageSize);
                        }
                    }
                    else
                    {
                        if (item.length > 1)
                        {
                            filterPageSize.filters[filterPageSize.filters.length] = { field: filterColumn, operator: "gte", value: item[startIndexTemp][filterColumn] };
                        }

                        if (filterPageSize.filters.length > 0)
                        {
                            filter.filters.push(filterPageSize);
                        }
                    }
                }

                //reset filter will trigger databound so need to save the selected value. Eg: ou databound will always set to user default ou.
                if (filter.filters.length > 0)
                {
                    //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                    if (_compareFilter(filter, e.sender.dataSource.filter()) == false)
                    {
                        let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                        {
                            return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                        }).length;

                        let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                        {
                            return _parseKendoFilter(vax, indx, _.cloneDeep(filter));
                        }).length;

                        if (setBeforeCount != setAfterCount || e.sender._state == "" || e.sender._state == undefined)
                        {
                            e.sender.dataSource.filter(_.cloneDeep(filter));

                            let selectedIndex = -1;

                            let cachelength = e.sender.dataSource._view.length;
                            for (var i = 0; i < cachelength; i++)
                            {
                                if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                {
                                    selectedIndex = i;
                                    break;
                                }
                            }

                            //To highlight the selected value from drop down list.
                            if (selectedIndex !== -1)
                            {
                                e.sender.select(selectedIndex);
                            }

                            e.sender.dataSource._filter = _.cloneDeep(originalFilter);
                        }
                    }
                    else if (_compareFilter(filter, e.sender.dataSource.filter()) == true && (e.sender._state == "" || e.sender._state == undefined))
                    {
                        e.sender.dataSource.filter(_.cloneDeep(filter));

                        let selectedIndex = -1;

                        let cachelength = e.sender.dataSource._view.length;
                        for (var i = 0; i < cachelength; i++)
                        {
                            if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                            {
                                selectedIndex = i;
                                break;
                            }
                        }

                        //To highlight the selected value from drop down list.
                        if (selectedIndex !== -1)
                        {
                            e.sender.select(selectedIndex);
                        }

                        e.sender.dataSource._filter = _.cloneDeep(originalFilter);
                    }
                }
            }
            else
            {
                if (originalFilter !== undefined)
                {
                    if (originalFilter.filters !== undefined)
                    {
                        if (originalFilter.filters.length > 0)
                        {
                            //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                            if (_compareFilter(originalFilter, e.sender.dataSource.filter()) == false)
                            {
                                let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                                {
                                    return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                                }).length;

                                let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                                {
                                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                                }).length;

                                if (setBeforeCount != setAfterCount || e.sender._state == "" || e.sender._state == undefined)
                                {
                                    e.sender.dataSource.filter(_.cloneDeep(originalFilter));

                                    let selectedIndex = -1;

                                    let cachelength = e.sender.dataSource._view.length;
                                    for (var i = 0; i < cachelength; i++)
                                    {
                                        if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                        {
                                            selectedIndex = i;
                                            break;
                                        }
                                    }

                                    //To highlight the selected value from drop down list.
                                    if (selectedIndex !== -1)
                                    {
                                        e.sender.select(selectedIndex);
                                    }

                                    //no need to reassign filter as no paging filter was used
                                }
                            }
                            else if (_compareFilter(originalFilter, e.sender.dataSource.filter()) == true && (e.sender._state == "" || e.sender._state == undefined))
                            {
                                e.sender.dataSource.filter(_.cloneDeep(originalFilter));

                                let selectedIndex = -1;

                                let cachelength = e.sender.dataSource._view.length;
                                for (var i = 0; i < cachelength; i++)
                                {
                                    if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                    {
                                        selectedIndex = i;
                                        break;
                                    }
                                }

                                //To highlight the selected value from drop down list.
                                if (selectedIndex !== -1)
                                {
                                    e.sender.select(selectedIndex);
                                }

                                //no need to reassign filter as no paging filter was used
                            }
                        }
                    }
                }
            }
        }
        else
        {
            let filter = _.cloneDeep(originalFilter);

            if (filter != undefined)
            {
                if (filter.filters != undefined)
                {
                    if (filter.filters.length > 0)
                    {
                        //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                        if (_compareFilter(filter, e.sender.dataSource.filter()) == false)
                        {
                            let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                            {
                                return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                            }).length;

                            let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                            {
                                return _parseKendoFilter(vax, indx, _.cloneDeep(filter));
                            }).length;

                            if (setBeforeCount != setAfterCount)
                            {
                                e.sender.dataSource.filter(filter);

                                if (e.sender.value() !== "" && e.sender.value() !== "CC ID is required.")
                                {
                                    //e.sender.listView.value([]);
                                    //e.sender._clearText();
                                    //e.sender._accessor('');
                                    //if (e.sender._isFilterEnabled())
                                    //{
                                    //    e.sender._filter({
                                    //        word: '',
                                    //        open: false
                                    //    });
                                    //}
                                    //e.sender._change();
                                }
                            }
                        }
                        else
                        {
                            if (currentComboboxText == "")
                            {
                                if (e.sender.value() !== "" && e.sender.value() !== "CC ID is required.")
                                {
                                    //e.sender.listView.value([]);
                                    //e.sender._clearText();
                                    //e.sender._accessor('');
                                    //if (e.sender._isFilterEnabled())
                                    //{
                                    //    e.sender._filter({
                                    //        word: '',
                                    //        open: false
                                    //    });
                                    //}
                                    //e.sender._change();
                                }
                            }
                        }
                    }
                }
            }
        }

        tmpFilteringDS = null;
    };

    let _comboBoxOpenEvent_EnableAll = function (e, filterCol, passThis)
    {
        if (typeof e == "undefined"
            || typeof e.sender == "undefined"
            || typeof e.sender.dataSource == "undefined"
            || e.sender.dataSource.data().length == 0
            || typeof e.sender.dataSource.options == "undefined"
            || typeof e.sender.dataSource.options.schema == "undefined"
            || typeof e.sender.dataSource.options.schema.model == "undefined"
            || typeof e.sender.dataSource.options.schema.model.id == "undefined")
        {
            return;
        }

        //clean out the filters
        let originalFilter = _cleanFilter(e.sender.dataSource._filter);

        //clean all tag!!!
        let cachelengthMain = e.sender.dataSource._data.length;
        for (var i = 0; i < cachelengthMain; i++)
        {
            if (e.sender.dataSource._data[i].setInactive !== undefined)
            {
                delete e.sender.dataSource._data[i].setInactive;
                //break;
            }
        }

        let currentComboboxValue = e.sender.value();
        let currentComboboxText = e.sender.text();

        let tmpFilteringDS = e.sender.dataSource.data().toJSON();
        let ExpectedItem = {};

        //means in correct event, not clear text
        //always start to find value from text only
        if (currentComboboxText != "")
        {
            //first, find the correct row and set the correct combobox value and text
            if (e.sender.dataItem() !== null && e.sender.dataItem() !== undefined && typeof e.sender.dataItem() !== "undefined")
            {
                ExpectedItem = _.cloneDeep(e.sender.dataItem());
            }
            else
            {
                //attempt to find missing row
                ExpectedItem = _findMissingExpecteddataItem(e.sender.options.dataTextField, e.sender.options.dataValueField, _.cloneDeep(originalFilter), tmpFilteringDS, currentComboboxText, currentComboboxValue, e.sender.dataSource.options.schema.model.id);
            }

            if (JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
            {
                if (currentComboboxValue.toString() !== ExpectedItem["id"].toString())
                    currentComboboxValue = ExpectedItem["id"];

                if (currentComboboxText.toString() !== ExpectedItem[e.sender.options.dataTextField].toString())
                    currentComboboxText = ExpectedItem[e.sender.options.dataTextField];
            }
            else
            {
                //last, assume nothing is selected
                currentComboboxText = "";
                currentComboboxValue = "";
            }
        }
        else
        {
            //text is empty, indicating that combo box is being cleared, should clear currentComboboxValue
            currentComboboxValue = "";
        }

        //append only when you are sure that current combobox got value and found selected row
        if (typeof currentComboboxValue !== "undefined" && currentComboboxValue !== "" && currentComboboxValue !== null && e.sender._state !== "filter" && JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
        {
            //check if expected item being filtered off by form's custom filter
            let item = tmpFilteringDS.filter(function (vax, indx)
            {
                return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
            });

            item = item.find(function (currentValue)
            {
                if (typeof currentValue !== "undefined" && currentValue !== null)
                    return currentValue[ExpectedItem["idField"].toString()].toString() === ExpectedItem["id"].toString();

                return false;
            });

            //if doesn't exist in view means kena filtered off, append it back
            if (typeof item === "undefined")
            {
                originalFilter = { filters: [originalFilter], logic: "or" };
                originalFilter.filters.push({
                    field: ExpectedItem["idField"].toString(),
                    operator: "eq",
                    value: ExpectedItem["id"],
                    CustomFlag: true
                });

                //tag this line!
                let cacheLength = e.sender.dataSource._data.length;
                for (var i = 0; i < cacheLength; i++)
                {
                    let currentitem = e.sender.dataSource._data[i][ExpectedItem["idField"].toString()];

                    if (typeof currentitem !== "undefined" && currentitem !== null)
                    {
                        if (currentitem.toString() === ExpectedItem["id"].toString())
                        {
                            e.sender.dataSource._data[i].setInactive = true;
                            break;
                        }
                    }
                }

            }
        }

        //Start filtering data.
        if (currentComboboxText != "" && currentComboboxValue != "")
        {
            if (e.sender.dataSource.pageSize() !== undefined)
            {
                //apply filter
                let item = tmpFilteringDS.filter(function (vax, indx)
                {
                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                });

                if (typeof e.sender.dataSource.sort() !== "undefined")
                {
                    let sortArray = [];

                    if (typeof e.sender.dataSource.sort().length === "undefined")
                    {
                        if (e.sender.dataSource.sort().dir === "desc")
                        {
                            sortArray.push("-" + e.sender.dataSource.sort().field);
                        }
                        else
                        {
                            sortArray.push(e.sender.dataSource.sort().field);
                        }
                    }
                    else
                    {
                        for (var j = 0; j < e.sender.dataSource.sort().length; j++)
                        {
                            if (e.sender.dataSource.sort()[j].dir === "desc")
                            {
                                sortArray.push("-" + e.sender.dataSource.sort()[j].field);
                            }
                            else
                            {
                                sortArray.push(e.sender.dataSource.sort()[j].field);
                            }
                        }
                    }

                    switch (sortArray.length)
                    {
                        case 0:
                            break;
                        case 1:
                            item = item.sortBy(sortArray[0]);
                            break;
                        case 2:
                            item = item.sortBy(sortArray[0], sortArray[1]);
                            break;
                        case 3:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2]);
                            break;
                        case 4:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3]);
                            break;
                        case 5:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4]);
                            break;
                        case 6:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5]);
                            break;
                        case 7:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6]);
                            break;
                        case 8:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7]);
                            break;
                        case 9:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7], sortArray[8]);
                            break;
                        case 10:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7], sortArray[8], sortArray[9]);
                            break;
                        default:
                            break;
                    }
                }

                let TempPageSize = e.sender.dataSource.pageSize();

                let ViewIndex = item.findIndex(function (vax)
                {
                    if (typeof vax !== "undefined" && vax !== null)
                    {
                        if (typeof vax[ExpectedItem["idField"].toString()] !== "undefined" && vax[ExpectedItem["idField"].toString()] !== null)
                        {
                            if (vax[ExpectedItem["idField"].toString()].toString() === ExpectedItem["id"].toString())
                            {
                                return true;
                            }
                        }
                    }

                    return false;
                });

                let startIndexTemp = (ViewIndex < 0) ? 0 : (ViewIndex.Nminus(TempPageSize.Ndiv(2)).NtoDP(0));
                startIndexTemp = (startIndexTemp < 0) ? 0 : startIndexTemp;

                let filter = _.cloneDeep(originalFilter);
                if (!filter)
                {
                    filter = { filters: [], logic: "and" };
                }
                else
                {
                    if (filter.logic != "and")
                    {
                        filter = { filters: [filter], logic: "and" };
                    }
                };

                let filterPageSize = { filters: [], logic: "or" };

                let filterColumn = e.sender.options.dataTextField;
                if (filterCol != undefined)
                {
                    filterColumn = filterCol;
                }

                if (item.length > TempPageSize)
                {
                    let isSorted = true;

                    if (item.length > 100)
                    {
                        //assume sorted, can't handle, let the form handle itself
                        isSorted = true;
                    }
                    else
                    {
                        for (var i = startIndexTemp; i < startIndexTemp + TempPageSize; i++)
                        {
                            if (i < item.length - 1)
                            {
                                if (item[i][filterColumn] > item[i + 1][filterColumn])
                                {
                                    isSorted = false;
                                    break;
                                }
                            }
                            else
                            {
                                break;
                            }
                        }
                    }

                    //page only when view > 1
                    if (!isSorted)
                    {
                        for (var i = startIndexTemp; i < startIndexTemp + TempPageSize; i++)
                        {
                            if (i < item.length && item.length > 1)
                            {
                                filterPageSize.filters[filterPageSize.filters.length] = { field: filterColumn, operator: "eq", value: item[i][filterColumn] };
                            }
                            else
                            {
                                break;
                            }
                        }

                        if (filterPageSize.filters.length > 0)
                        {
                            filter.filters.push(filterPageSize);
                        }
                    }
                    else
                    {
                        if (item.length > 1)
                        {
                            filterPageSize.filters[filterPageSize.filters.length] = { field: filterColumn, operator: "gte", value: item[startIndexTemp][filterColumn] };
                        }

                        if (filterPageSize.filters.length > 0)
                        {
                            filter.filters.push(filterPageSize);
                        }
                    }
                }

                //reset filter will trigger databound so need to save the selected value. Eg: ou databound will always set to user default ou.
                if (filter.filters.length > 0)
                {
                    //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                    if (_compareFilter(filter, e.sender.dataSource.filter()) == false)
                    {
                        let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                        {
                            return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                        }).length;

                        let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                        {
                            return _parseKendoFilter(vax, indx, _.cloneDeep(filter));
                        }).length;

                        if (setBeforeCount != setAfterCount || e.sender._state == "" || e.sender._state == undefined)
                        {
                            e.sender.dataSource.filter(_.cloneDeep(filter));

                            let selectedIndex = -1;

                            let cachelength = e.sender.dataSource._view.length;
                            for (var i = 0; i < cachelength; i++)
                            {
                                if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                {
                                    selectedIndex = i;
                                    break;
                                }
                            }

                            //To highlight the selected value from drop down list.
                            if (selectedIndex !== -1)
                            {
                                e.sender.select(selectedIndex);
                            }

                            e.sender.dataSource._filter = _.cloneDeep(originalFilter);
                        }
                    }
                    else if (_compareFilter(filter, e.sender.dataSource.filter()) == true && (e.sender._state == "" || e.sender._state == undefined))
                    {
                        e.sender.dataSource.filter(_.cloneDeep(filter));

                        let selectedIndex = -1;

                        let cachelength = e.sender.dataSource._view.length;
                        for (var i = 0; i < cachelength; i++)
                        {
                            if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                            {
                                selectedIndex = i;
                                break;
                            }
                        }

                        //To highlight the selected value from drop down list.
                        if (selectedIndex !== -1)
                        {
                            e.sender.select(selectedIndex);
                        }

                        e.sender.dataSource._filter = _.cloneDeep(originalFilter);
                    }
                }
            }
            else
            {
                if (originalFilter !== undefined)
                {
                    if (originalFilter.filters !== undefined)
                    {
                        if (originalFilter.filters.length > 0)
                        {
                            //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                            if (_compareFilter(originalFilter, e.sender.dataSource.filter()) == false)
                            {
                                let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                                {
                                    return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                                }).length;

                                let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                                {
                                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                                }).length;

                                if (setBeforeCount != setAfterCount || e.sender._state == "" || e.sender._state == undefined)
                                {
                                    e.sender.dataSource.filter(_.cloneDeep(originalFilter));

                                    let selectedIndex = -1;

                                    let cachelength = e.sender.dataSource._view.length;
                                    for (var i = 0; i < cachelength; i++)
                                    {
                                        if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                        {
                                            selectedIndex = i;
                                            break;
                                        }
                                    }

                                    //To highlight the selected value from drop down list.
                                    if (selectedIndex !== -1)
                                    {
                                        e.sender.select(selectedIndex);
                                    }

                                    //no need to reassign filter as no paging filter was used
                                }
                            }
                            else if (_compareFilter(originalFilter, e.sender.dataSource.filter()) == true && (e.sender._state == "" || e.sender._state == undefined))
                            {
                                e.sender.dataSource.filter(_.cloneDeep(originalFilter));

                                let selectedIndex = -1;

                                let cachelength = e.sender.dataSource._view.length;
                                for (var i = 0; i < cachelength; i++)
                                {
                                    if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                    {
                                        selectedIndex = i;
                                        break;
                                    }
                                }

                                //To highlight the selected value from drop down list.
                                if (selectedIndex !== -1)
                                {
                                    e.sender.select(selectedIndex);
                                }

                                //no need to reassign filter as no paging filter was used
                            }
                        }
                    }
                }
            }
        }
        else
        {
            let filter = _.cloneDeep(originalFilter);

            if (filter != undefined)
            {
                if (filter.filters != undefined)
                {
                    if (filter.filters.length > 0)
                    {
                        //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                        if (_compareFilter(filter, e.sender.dataSource.filter()) == false)
                        {
                            let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                            {
                                return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                            }).length;

                            let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                            {
                                return _parseKendoFilter(vax, indx, _.cloneDeep(filter));
                            }).length;

                            if (setBeforeCount != setAfterCount)
                            {
                                e.sender.dataSource.filter(filter);

                                if (e.sender.value() !== "" && e.sender.value() !== "CC ID is required.")
                                {
                                    //e.sender.listView.value([]);
                                    //e.sender._clearText();
                                    //e.sender._accessor('');
                                    //if (e.sender._isFilterEnabled())
                                    //{
                                    //    e.sender._filter({
                                    //        word: '',
                                    //        open: false
                                    //    });
                                    //}
                                    //e.sender._change();
                                }
                            }
                        }
                        else
                        {
                            if (currentComboboxText == "")
                            {
                                if (e.sender.value() !== "" && e.sender.value() !== "CC ID is required.")
                                {
                                    //e.sender.listView.value([]);
                                    //e.sender._clearText();
                                    //e.sender._accessor('');
                                    //if (e.sender._isFilterEnabled())
                                    //{
                                    //    e.sender._filter({
                                    //        word: '',
                                    //        open: false
                                    //    });
                                    //}
                                    //e.sender._change();
                                }
                            }
                        }
                    }
                }
            }
        }

        tmpFilteringDS = null;
    };

    let _comboBoxOpenEvent_EnableInactive = function (e, filterCol, passThis)
    {
        if (typeof e == "undefined"
            || typeof e.sender == "undefined"
            || typeof e.sender.dataSource == "undefined"
            || e.sender.dataSource.data().length == 0
            || typeof e.sender.dataSource.options == "undefined"
            || typeof e.sender.dataSource.options.schema == "undefined"
            || typeof e.sender.dataSource.options.schema.model == "undefined"
            || typeof e.sender.dataSource.options.schema.model.id == "undefined")
        {
            return;
        }

        //clean out the filters
        let originalFilter = _cleanFilter(e.sender.dataSource._filter);

        //clean all tag!!!
        let cachelengthMain = e.sender.dataSource._data.length;
        for (var i = 0; i < cachelengthMain; i++)
        {
            if (e.sender.dataSource._data[i].setInactive !== undefined)
            {
                delete e.sender.dataSource._data[i].setInactive;
                //break;
            }
        }

        let currentComboboxValue = e.sender.value();
        let currentComboboxText = e.sender.text();

        let tmpFilteringDS = e.sender.dataSource.data().toJSON();
        let ExpectedItem = {};

        //means in correct event, not clear text
        //always start to find value from text only
        if (currentComboboxText != "")
        {
            //first, find the correct row and set the correct combobox value and text
            if (e.sender.dataItem() !== null && e.sender.dataItem() !== undefined && typeof e.sender.dataItem() !== "undefined")
            {
                ExpectedItem = _.cloneDeep(e.sender.dataItem());
            }
            else
            {
                //attempt to find missing row
                ExpectedItem = _findMissingExpecteddataItem(e.sender.options.dataTextField, e.sender.options.dataValueField, _.cloneDeep(originalFilter), tmpFilteringDS, currentComboboxText, currentComboboxValue, e.sender.dataSource.options.schema.model.id);
            }

            if (JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
            {
                if (currentComboboxValue.toString() !== ExpectedItem["id"].toString())
                    currentComboboxValue = ExpectedItem["id"];

                if (currentComboboxText.toString() !== ExpectedItem[e.sender.options.dataTextField].toString())
                    currentComboboxText = ExpectedItem[e.sender.options.dataTextField];
            }
            else
            {
                //last, assume nothing is selected
                currentComboboxText = "";
                currentComboboxValue = "";
            }
        }
        else
        {
            //text is empty, indicating that combo box is being cleared, should clear currentComboboxValue
            currentComboboxValue = "";
        }
        
        if (e.sender.dataSource.data()[0].IsControlAccount !== undefined)
        {
            originalFilter = _pushCustomFilter("CtrlAcc", originalFilter);
        }

        //append only when you are sure that current combobox got value and found selected row
        if (typeof currentComboboxValue !== "undefined" && currentComboboxValue !== "" && currentComboboxValue !== null && e.sender._state !== "filter" && JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
        {
            //append back if expecteditem is being filtered off by default filter
            if (ExpectedItem["IsControlAccount"] === true)
            {
                originalFilter = { filters: [originalFilter], logic: "or" };
                originalFilter.filters.push({
                    field: ExpectedItem["idField"].toString(),
                    operator: "eq",
                    value: ExpectedItem["id"],
                    CustomFlag: true
                });
            }
            else
            {
                //check if expected item being filtered off by form's custom filter
                let item = tmpFilteringDS.filter(function (vax, indx)
                {
                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                });

                item = item.find(function (currentValue)
                {
                    if (typeof currentValue !== "undefined" && currentValue !== null)
                        return currentValue[ExpectedItem["idField"].toString()].toString() === ExpectedItem["id"].toString();

                    return false;
                });

                //if doesn't exist in view means kena filtered off, append it back
                if (typeof item === "undefined")
                {
                    originalFilter = { filters: [originalFilter], logic: "or" };
                    originalFilter.filters.push({
                        field: ExpectedItem["idField"].toString(),
                        operator: "eq",
                        value: ExpectedItem["id"],
                        CustomFlag: true
                    });

                    //tag this line!
                    let cacheLength = e.sender.dataSource._data.length;
                    for (var i = 0; i < cacheLength; i++)
                    {
                        let currentitem = e.sender.dataSource._data[i][ExpectedItem["idField"].toString()];

                        if (typeof currentitem !== "undefined" && currentitem !== null)
                        {
                            if (currentitem.toString() === ExpectedItem["id"].toString())
                            {
                                e.sender.dataSource._data[i].setInactive = true;
                                break;
                            }
                        }
                    }
                }
            }
        }

        //Start filtering data.
        if (currentComboboxText != "" && currentComboboxValue != "")
        {
            if (e.sender.dataSource.pageSize() !== undefined)
            {
                //apply filter
                let item = tmpFilteringDS.filter(function (vax, indx)
                {
                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                });

                if (typeof e.sender.dataSource.sort() !== "undefined")
                {
                    let sortArray = [];

                    if (typeof e.sender.dataSource.sort().length === "undefined")
                    {
                        if (e.sender.dataSource.sort().dir === "desc")
                        {
                            sortArray.push("-" + e.sender.dataSource.sort().field);
                        }
                        else
                        {
                            sortArray.push(e.sender.dataSource.sort().field);
                        }
                    }
                    else
                    {
                        for (var j = 0; j < e.sender.dataSource.sort().length; j++)
                        {
                            if (e.sender.dataSource.sort()[j].dir === "desc")
                            {
                                sortArray.push("-" + e.sender.dataSource.sort()[j].field);
                            }
                            else
                            {
                                sortArray.push(e.sender.dataSource.sort()[j].field);
                            }
                        }
                    }

                    switch (sortArray.length)
                    {
                        case 0:
                            break;
                        case 1:
                            item = item.sortBy(sortArray[0]);
                            break;
                        case 2:
                            item = item.sortBy(sortArray[0], sortArray[1]);
                            break;
                        case 3:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2]);
                            break;
                        case 4:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3]);
                            break;
                        case 5:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4]);
                            break;
                        case 6:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5]);
                            break;
                        case 7:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6]);
                            break;
                        case 8:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7]);
                            break;
                        case 9:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7], sortArray[8]);
                            break;
                        case 10:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7], sortArray[8], sortArray[9]);
                            break;
                        default:
                            break;
                    }
                }

                let TempPageSize = e.sender.dataSource.pageSize();

                let ViewIndex = item.findIndex(function (vax)
                {
                    if (typeof vax !== "undefined" && vax !== null)
                    {
                        if (typeof vax[ExpectedItem["idField"].toString()] !== "undefined" && vax[ExpectedItem["idField"].toString()] !== null)
                        {
                            if (vax[ExpectedItem["idField"].toString()].toString() === ExpectedItem["id"].toString())
                            {
                                return true;
                            }
                        }
                    }

                    return false;
                });

                let startIndexTemp = (ViewIndex < 0) ? 0 : (ViewIndex.Nminus(TempPageSize.Ndiv(2)).NtoDP(0));
                startIndexTemp = (startIndexTemp < 0) ? 0 : startIndexTemp;

                let filter = _.cloneDeep(originalFilter);
                if (!filter)
                {
                    filter = { filters: [], logic: "and" };
                }
                else
                {
                    if (filter.logic != "and")
                    {
                        filter = { filters: [filter], logic: "and" };
                    }
                };

                let filterPageSize = { filters: [], logic: "or" };

                let filterColumn = e.sender.options.dataTextField;
                if (filterCol != undefined)
                {
                    filterColumn = filterCol;
                }

                if (item.length > TempPageSize)
                {
                    let isSorted = true;

                    if (item.length > 100)
                    {
                        //assume sorted, can't handle, let the form handle itself
                        isSorted = true;
                    }
                    else
                    {
                        for (var i = startIndexTemp; i < startIndexTemp + TempPageSize; i++)
                        {
                            if (i < item.length - 1)
                            {
                                if (item[i][filterColumn] > item[i + 1][filterColumn])
                                {
                                    isSorted = false;
                                    break;
                                }
                            }
                            else
                            {
                                break;
                            }
                        }
                    }

                    //page only when view > 1
                    if (!isSorted)
                    {
                        for (var i = startIndexTemp; i < startIndexTemp + TempPageSize; i++)
                        {
                            if (i < item.length && item.length > 1)
                            {
                                filterPageSize.filters[filterPageSize.filters.length] = { field: filterColumn, operator: "eq", value: item[i][filterColumn] };
                            }
                            else
                            {
                                break;
                            }
                        }

                        if (filterPageSize.filters.length > 0)
                        {
                            filter.filters.push(filterPageSize);
                        }
                    }
                    else
                    {
                        if (item.length > 1)
                        {
                            filterPageSize.filters[filterPageSize.filters.length] = { field: filterColumn, operator: "gte", value: item[startIndexTemp][filterColumn] };
                        }

                        if (filterPageSize.filters.length > 0)
                        {
                            filter.filters.push(filterPageSize);
                        }
                    }
                }

                //reset filter will trigger databound so need to save the selected value. Eg: ou databound will always set to user default ou.
                if (filter.filters.length > 0)
                {
                    //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                    if (_compareFilter(filter, e.sender.dataSource.filter()) == false)
                    {
                        let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                        {
                            return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                        }).length;

                        let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                        {
                            return _parseKendoFilter(vax, indx, _.cloneDeep(filter));
                        }).length;

                        if (setBeforeCount != setAfterCount || e.sender._state == "" || e.sender._state == undefined)
                        {
                            e.sender.dataSource.filter(_.cloneDeep(filter));

                            let selectedIndex = -1;

                            let cachelength = e.sender.dataSource._view.length;
                            for (var i = 0; i < cachelength; i++)
                            {
                                if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                {
                                    selectedIndex = i;
                                    break;
                                }
                            }

                            //To highlight the selected value from drop down list.
                            if (selectedIndex !== -1)
                            {
                                e.sender.select(selectedIndex);
                            }

                            e.sender.dataSource._filter = _.cloneDeep(originalFilter);
                        }
                    }
                    else if (_compareFilter(filter, e.sender.dataSource.filter()) == true && (e.sender._state == "" || e.sender._state == undefined))
                    {
                        e.sender.dataSource.filter(_.cloneDeep(filter));

                        let selectedIndex = -1;

                        let cachelength = e.sender.dataSource._view.length;
                        for (var i = 0; i < cachelength; i++)
                        {
                            if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                            {
                                selectedIndex = i;
                                break;
                            }
                        }

                        //To highlight the selected value from drop down list.
                        if (selectedIndex !== -1)
                        {
                            e.sender.select(selectedIndex);
                        }

                        e.sender.dataSource._filter = _.cloneDeep(originalFilter);
                    }
                }
            }
            else
            {
                if (originalFilter !== undefined)
                {
                    if (originalFilter.filters !== undefined)
                    {
                        if (originalFilter.filters.length > 0)
                        {
                            //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                            if (_compareFilter(originalFilter, e.sender.dataSource.filter()) == false)
                            {
                                let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                                {
                                    return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                                }).length;

                                let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                                {
                                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                                }).length;

                                if (setBeforeCount != setAfterCount || e.sender._state == "" || e.sender._state == undefined)
                                {
                                    e.sender.dataSource.filter(_.cloneDeep(originalFilter));

                                    let selectedIndex = -1;

                                    let cachelength = e.sender.dataSource._view.length;
                                    for (var i = 0; i < cachelength; i++)
                                    {
                                        if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                        {
                                            selectedIndex = i;
                                            break;
                                        }
                                    }

                                    //To highlight the selected value from drop down list.
                                    if (selectedIndex !== -1)
                                    {
                                        e.sender.select(selectedIndex);
                                    }

                                    //no need to reassign filter as no paging filter was used
                                }
                            }
                            else if (_compareFilter(originalFilter, e.sender.dataSource.filter()) == true && (e.sender._state == "" || e.sender._state == undefined))
                            {
                                e.sender.dataSource.filter(_.cloneDeep(originalFilter));

                                let selectedIndex = -1;

                                let cachelength = e.sender.dataSource._view.length;
                                for (var i = 0; i < cachelength; i++)
                                {
                                    if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                    {
                                        selectedIndex = i;
                                        break;
                                    }
                                }

                                //To highlight the selected value from drop down list.
                                if (selectedIndex !== -1)
                                {
                                    e.sender.select(selectedIndex);
                                }

                                //no need to reassign filter as no paging filter was used
                            }
                        }
                    }
                }
            }
        }
        else
        {
            let filter = _.cloneDeep(originalFilter);

            if (filter != undefined)
            {
                if (filter.filters != undefined)
                {
                    if (filter.filters.length > 0)
                    {
                        //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                        if (_compareFilter(filter, e.sender.dataSource.filter()) == false)
                        {
                            let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                            {
                                return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                            }).length;

                            let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                            {
                                return _parseKendoFilter(vax, indx, _.cloneDeep(filter));
                            }).length;

                            if (setBeforeCount != setAfterCount)
                            {
                                e.sender.dataSource.filter(filter);

                                if (e.sender.value() !== "" && e.sender.value() !== "CC ID is required.")
                                {
                                    //e.sender.listView.value([]);
                                    //e.sender._clearText();
                                    //e.sender._accessor('');
                                    //if (e.sender._isFilterEnabled())
                                    //{
                                    //    e.sender._filter({
                                    //        word: '',
                                    //        open: false
                                    //    });
                                    //}
                                    //e.sender._change();
                                }
                            }
                        }
                        else
                        {
                            if (currentComboboxText == "")
                            {
                                if (e.sender.value() !== "" && e.sender.value() !== "CC ID is required.")
                                {
                                    //e.sender.listView.value([]);
                                    //e.sender._clearText();
                                    //e.sender._accessor('');
                                    //if (e.sender._isFilterEnabled())
                                    //{
                                    //    e.sender._filter({
                                    //        word: '',
                                    //        open: false
                                    //    });
                                    //}
                                    //e.sender._change();
                                }
                            }
                        }
                    }
                }
            }
        }

        tmpFilteringDS = null;
    };

    let _comboBoxOpenEvent_EnableCtrlAcc = function (e, filterCol, passThis)
    {
        if (typeof e == "undefined"
            || typeof e.sender == "undefined"
            || typeof e.sender.dataSource == "undefined"
            || e.sender.dataSource.data().length == 0
            || typeof e.sender.dataSource.options == "undefined"
            || typeof e.sender.dataSource.options.schema == "undefined"
            || typeof e.sender.dataSource.options.schema.model == "undefined"
            || typeof e.sender.dataSource.options.schema.model.id == "undefined")
        {
            return;
        }

        //clean out the filters
        let originalFilter = _cleanFilter(e.sender.dataSource._filter);

        //clean all tag!!!
        let cachelengthMain = e.sender.dataSource._data.length;
        for (var i = 0; i < cachelengthMain; i++)
        {
            if (e.sender.dataSource._data[i].setInactive !== undefined)
            {
                delete e.sender.dataSource._data[i].setInactive;
                //break;
            }
        }

        let currentComboboxValue = e.sender.value();
        let currentComboboxText = e.sender.text();

        let tmpFilteringDS = e.sender.dataSource.data().toJSON();
        let ExpectedItem = {};

        //means in correct event, not clear text
        //always start to find value from text only
        if (currentComboboxText != "")
        {
            //first, find the correct row and set the correct combobox value and text
            if (e.sender.dataItem() !== null && e.sender.dataItem() !== undefined && typeof e.sender.dataItem() !== "undefined")
            {
                ExpectedItem = _.cloneDeep(e.sender.dataItem());
            }
            else
            {
                //attempt to find missing row
                ExpectedItem = _findMissingExpecteddataItem(e.sender.options.dataTextField, e.sender.options.dataValueField, _.cloneDeep(originalFilter), tmpFilteringDS, currentComboboxText, currentComboboxValue, e.sender.dataSource.options.schema.model.id);
            }

            if (JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
            {
                if (currentComboboxValue.toString() !== ExpectedItem["id"].toString())
                    currentComboboxValue = ExpectedItem["id"];

                if (currentComboboxText.toString() !== ExpectedItem[e.sender.options.dataTextField].toString())
                    currentComboboxText = ExpectedItem[e.sender.options.dataTextField];
            }
            else
            {
                //last, assume nothing is selected
                currentComboboxText = "";
                currentComboboxValue = "";
            }
        }
        else
        {
            //text is empty, indicating that combo box is being cleared, should clear currentComboboxValue
            currentComboboxValue = "";
        }

        if (e.sender.dataSource.data()[0].Active !== undefined)
        {
            originalFilter = _pushCustomFilter("Active", originalFilter);
        }
        
        if (e.sender.dataSource.data()[0].Status !== undefined)
        {
            originalFilter = _pushCustomFilter("Status", originalFilter);
        }

        if (e.sender.dataSource.data()[0].StatusID !== undefined)
        {
            originalFilter = _pushCustomFilter("StatusID", originalFilter);
        }

        //append only when you are sure that current combobox got value and found selected row
        if (typeof currentComboboxValue !== "undefined" && currentComboboxValue !== "" && currentComboboxValue !== null && e.sender._state !== "filter" && JSON.stringify(ExpectedItem) !== "{}" && typeof ExpectedItem !== "undefined" && ExpectedItem !== null)
        {
            //append back if expecteditem is being filtered off by default filter
            if (ExpectedItem["Active"] === false || ($.inArray(ExpectedItem["Status"], ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) > -1) || ($.inArray(ExpectedItem["StatusID"], ["11", 11]) > -1))
            {
                originalFilter = { filters: [originalFilter], logic: "or" };
                originalFilter.filters.push({
                    field: ExpectedItem["idField"].toString(),
                    operator: "eq",
                    value: ExpectedItem["id"],
                    CustomFlag: true
                });
            }
            else
            {
                //check if expected item being filtered off by form's custom filter
                let item = tmpFilteringDS.filter(function (vax, indx)
                {
                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                });

                item = item.find(function (currentValue)
                {
                    if (typeof currentValue !== "undefined" && currentValue !== null)
                        return currentValue[ExpectedItem["idField"].toString()].toString() === ExpectedItem["id"].toString();

                    return false;
                });

                //if doesn't exist in view means kena filtered off, append it back
                if (typeof item === "undefined")
                {
                    originalFilter = { filters: [originalFilter], logic: "or" };
                    originalFilter.filters.push({
                        field: ExpectedItem["idField"].toString(),
                        operator: "eq",
                        value: ExpectedItem["id"],
                        CustomFlag: true
                    });

                    //tag this line!
                    let cacheLength = e.sender.dataSource._data.length;
                    for (var i = 0; i < cacheLength; i++)
                    {
                        let currentitem = e.sender.dataSource._data[i][ExpectedItem["idField"].toString()];

                        if (typeof currentitem !== "undefined" && currentitem !== null)
                        {
                            if (currentitem.toString() === ExpectedItem["id"].toString())
                            {
                                e.sender.dataSource._data[i].setInactive = true;
                                break;
                            }
                        }
                    }
                }
            }
        }

        //Start filtering data.
        if (currentComboboxText != "" && currentComboboxValue != "")
        {
            if (e.sender.dataSource.pageSize() !== undefined)
            {
                //apply filter
                let item = tmpFilteringDS.filter(function (vax, indx)
                {
                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                });

                if (typeof e.sender.dataSource.sort() !== "undefined")
                {
                    let sortArray = [];

                    if (typeof e.sender.dataSource.sort().length === "undefined")
                    {
                        if (e.sender.dataSource.sort().dir === "desc")
                        {
                            sortArray.push("-" + e.sender.dataSource.sort().field);
                        }
                        else
                        {
                            sortArray.push(e.sender.dataSource.sort().field);
                        }
                    }
                    else
                    {
                        for (var j = 0; j < e.sender.dataSource.sort().length; j++)
                        {
                            if (e.sender.dataSource.sort()[j].dir === "desc")
                            {
                                sortArray.push("-" + e.sender.dataSource.sort()[j].field);
                            }
                            else
                            {
                                sortArray.push(e.sender.dataSource.sort()[j].field);
                            }
                        }
                    }

                    switch (sortArray.length)
                    {
                        case 0:
                            break;
                        case 1:
                            item = item.sortBy(sortArray[0]);
                            break;
                        case 2:
                            item = item.sortBy(sortArray[0], sortArray[1]);
                            break;
                        case 3:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2]);
                            break;
                        case 4:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3]);
                            break;
                        case 5:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4]);
                            break;
                        case 6:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5]);
                            break;
                        case 7:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6]);
                            break;
                        case 8:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7]);
                            break;
                        case 9:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7], sortArray[8]);
                            break;
                        case 10:
                            item = item.sortBy(sortArray[0], sortArray[1], sortArray[2], sortArray[3], sortArray[4], sortArray[5], sortArray[6], sortArray[7], sortArray[8], sortArray[9]);
                            break;
                        default:
                            break;
                    }
                }

                let TempPageSize = e.sender.dataSource.pageSize();

                let ViewIndex = item.findIndex(function (vax)
                {
                    if (typeof vax !== "undefined" && vax !== null)
                    {
                        if (typeof vax[ExpectedItem["idField"].toString()] !== "undefined" && vax[ExpectedItem["idField"].toString()] !== null)
                        {
                            if (vax[ExpectedItem["idField"].toString()].toString() === ExpectedItem["id"].toString())
                            {
                                return true;
                            }
                        }
                    }

                    return false;
                });

                let startIndexTemp = (ViewIndex < 0) ? 0 : (ViewIndex.Nminus(TempPageSize.Ndiv(2)).NtoDP(0));
                startIndexTemp = (startIndexTemp < 0) ? 0 : startIndexTemp;

                let filter = _.cloneDeep(originalFilter);
                if (!filter)
                {
                    filter = { filters: [], logic: "and" };
                }
                else
                {
                    if (filter.logic != "and")
                    {
                        filter = { filters: [filter], logic: "and" };
                    }
                };

                let filterPageSize = { filters: [], logic: "or" };

                let filterColumn = e.sender.options.dataTextField;
                if (filterCol != undefined)
                {
                    filterColumn = filterCol;
                }

                if (item.length > TempPageSize)
                {
                    let isSorted = true;

                    if (item.length > 100)
                    {
                        //assume sorted, can't handle, let the form handle itself
                        isSorted = true;
                    }
                    else
                    {
                        for (var i = startIndexTemp; i < startIndexTemp + TempPageSize; i++)
                        {
                            if (i < item.length - 1)
                            {
                                if (item[i][filterColumn] > item[i + 1][filterColumn])
                                {
                                    isSorted = false;
                                    break;
                                }
                            }
                            else
                            {
                                break;
                            }
                        }
                    }

                    //page only when view > 1
                    if (!isSorted)
                    {
                        for (var i = startIndexTemp; i < startIndexTemp + TempPageSize; i++)
                        {
                            if (i < item.length && item.length > 1)
                            {
                                filterPageSize.filters[filterPageSize.filters.length] = { field: filterColumn, operator: "eq", value: item[i][filterColumn] };
                            }
                            else
                            {
                                break;
                            }
                        }

                        if (filterPageSize.filters.length > 0)
                        {
                            filter.filters.push(filterPageSize);
                        }
                    }
                    else
                    {
                        if (item.length > 1)
                        {
                            filterPageSize.filters[filterPageSize.filters.length] = { field: filterColumn, operator: "gte", value: item[startIndexTemp][filterColumn] };
                        }

                        if (filterPageSize.filters.length > 0)
                        {
                            filter.filters.push(filterPageSize);
                        }
                    }
                }

                //reset filter will trigger databound so need to save the selected value. Eg: ou databound will always set to user default ou.
                if (filter.filters.length > 0)
                {
                    //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                    if (_compareFilter(filter, e.sender.dataSource.filter()) == false)
                    {
                        let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                        {
                            return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                        }).length;

                        let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                        {
                            return _parseKendoFilter(vax, indx, _.cloneDeep(filter));
                        }).length;

                        if (setBeforeCount != setAfterCount || e.sender._state == "" || e.sender._state == undefined)
                        {
                            e.sender.dataSource.filter(_.cloneDeep(filter));

                            let selectedIndex = -1;

                            let cachelength = e.sender.dataSource._view.length;
                            for (var i = 0; i < cachelength; i++)
                            {
                                if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                {
                                    selectedIndex = i;
                                    break;
                                }
                            }

                            //To highlight the selected value from drop down list.
                            if (selectedIndex !== -1)
                            {
                                e.sender.select(selectedIndex);
                            }

                            e.sender.dataSource._filter = _.cloneDeep(originalFilter);
                        }
                    }
                    else if (_compareFilter(filter, e.sender.dataSource.filter()) == true && (e.sender._state == "" || e.sender._state == undefined))
                    {
                        e.sender.dataSource.filter(_.cloneDeep(filter));

                        let selectedIndex = -1;

                        let cachelength = e.sender.dataSource._view.length;
                        for (var i = 0; i < cachelength; i++)
                        {
                            if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                            {
                                selectedIndex = i;
                                break;
                            }
                        }

                        //To highlight the selected value from drop down list.
                        if (selectedIndex !== -1)
                        {
                            e.sender.select(selectedIndex);
                        }

                        e.sender.dataSource._filter = _.cloneDeep(originalFilter);
                    }
                }
            }
            else
            {
                if (originalFilter !== undefined)
                {
                    if (originalFilter.filters !== undefined)
                    {
                        if (originalFilter.filters.length > 0)
                        {
                            //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                            if (_compareFilter(originalFilter, e.sender.dataSource.filter()) == false)
                            {
                                let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                                {
                                    return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                                }).length;

                                let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                                {
                                    return _parseKendoFilter(vax, indx, _.cloneDeep(originalFilter));
                                }).length;

                                if (setBeforeCount != setAfterCount || e.sender._state == "" || e.sender._state == undefined)
                                {
                                    e.sender.dataSource.filter(_.cloneDeep(originalFilter));

                                    let selectedIndex = -1;

                                    let cachelength = e.sender.dataSource._view.length;
                                    for (var i = 0; i < cachelength; i++)
                                    {
                                        if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                        {
                                            selectedIndex = i;
                                            break;
                                        }
                                    }

                                    //To highlight the selected value from drop down list.
                                    if (selectedIndex !== -1)
                                    {
                                        e.sender.select(selectedIndex);
                                    }

                                    //no need to reassign filter as no paging filter was used
                                }
                            }
                            else if (_compareFilter(originalFilter, e.sender.dataSource.filter()) == true && (e.sender._state == "" || e.sender._state == undefined))
                            {
                                e.sender.dataSource.filter(_.cloneDeep(originalFilter));

                                let selectedIndex = -1;

                                let cachelength = e.sender.dataSource._view.length;
                                for (var i = 0; i < cachelength; i++)
                                {
                                    if (e.sender.dataSource._view[i][ExpectedItem["idField"]].toString() === ExpectedItem["id"].toString())
                                    {
                                        selectedIndex = i;
                                        break;
                                    }
                                }

                                //To highlight the selected value from drop down list.
                                if (selectedIndex !== -1)
                                {
                                    e.sender.select(selectedIndex);
                                }

                                //no need to reassign filter as no paging filter was used
                            }
                        }
                    }
                }
            }
        }
        else
        {
            let filter = _.cloneDeep(originalFilter);

            if (filter != undefined)
            {
                if (filter.filters != undefined)
                {
                    if (filter.filters.length > 0)
                    {
                        //filter only if it is not same as original, to workaround some issue, but not 100% solved.
                        if (_compareFilter(filter, e.sender.dataSource.filter()) == false)
                        {
                            let setBeforeCount = tmpFilteringDS.filter(function (vax, indx)
                            {
                                return _parseKendoFilter(vax, indx, _.cloneDeep(e.sender.dataSource.filter()));
                            }).length;

                            let setAfterCount = tmpFilteringDS.filter(function (vax, indx)
                            {
                                return _parseKendoFilter(vax, indx, _.cloneDeep(filter));
                            }).length;

                            if (setBeforeCount != setAfterCount)
                            {
                                e.sender.dataSource.filter(filter);

                                if (e.sender.value() !== "" && e.sender.value() !== "CC ID is required.")
                                {
                                    //e.sender.listView.value([]);
                                    //e.sender._clearText();
                                    //e.sender._accessor('');
                                    //if (e.sender._isFilterEnabled())
                                    //{
                                    //    e.sender._filter({
                                    //        word: '',
                                    //        open: false
                                    //    });
                                    //}
                                    //e.sender._change();
                                }
                            }
                        }
                        else
                        {
                            if (currentComboboxText == "")
                            {
                                if (e.sender.value() !== "" && e.sender.value() !== "CC ID is required.")
                                {
                                    //e.sender.listView.value([]);
                                    //e.sender._clearText();
                                    //e.sender._accessor('');
                                    //if (e.sender._isFilterEnabled())
                                    //{
                                    //    e.sender._filter({
                                    //        word: '',
                                    //        open: false
                                    //    });
                                    //}
                                    //e.sender._change();
                                }
                            }
                        }
                    }
                }
            }
        }

        tmpFilteringDS = null;
    };

    //Show and disable the normal combo box if datasource is empty.
    let _nmComboBoxNoRcdDisp = function (param)
    {
        //when read complete:
        if (param.datasource.length == 0)
        {
            param.comboBox.enable(false);
            param.comboBox.input.attr('placeholder', 'No Records...');
        }
        else
        {
            if (param.Status != undefined && param.Status != 'OP')
                param.comboBox.enable(false);
            else if (!param.Flag)
                param.comboBox.enable(true);
            else
                param.comboBox.enable(false);

            param.comboBox.input.attr('placeholder', '');
        }
    };

    //Show and disable the datagrid combo box if datasource is empty.
    //disable grid cell if kendo combobox datasource is empty.
    let _gridComboBoxNoDataDisableCell = function (param)
    {
        let colIdx = param.passThis.cellIndex(param.e.container);
        if (param.passThis.columns[colIdx].editor != undefined)
        {
            let combo = param.e.container
                .find('[data-role="combobox"]')
                .data('kendoComboBox');

            if (combo != undefined)
            {
                if (combo.dataSource.data().length == 0)
                {
                    param.passThis.closeCell();
                }
            }
        }
    }

    //display "No Records..." if kendo combobox datasource is empty.
    let _gridComboBoxNoRcdDisp = function (param)
    {
        for (var i = 0; i < param.passThis.columns.length; i++)
        {
            if (param.passThis.columns[i].editor != undefined)
            {
                for (var j = 0; j < param.data.length; j++)
                {
                    if (param.data[j].column == param.passThis.columns[i].field)
                    {
                        if (param.data[j].column == 'CCIDCodeCCIDDesc' && (param.gotAcc == false || (param.gotAcc == true && param.IsRequiredCCID == false)))
                        {

                        }
                        else
                        {
                            if (param.data[j].datasource._data.length == 0)
                            {
                                let rowIndex = param.passThis.items().index(param.passThis.select());
                                param.passThis._data[rowIndex][param.passThis.columns[i].field] = "No Records...";
                            }
                            else
                            {
                                let rowIndex = param.passThis.items().index(param.passThis.select());
                                if (param.passThis._data[rowIndex][param.passThis.columns[i].field] == "No Records...")
                                {
                                    param.passThis._data[rowIndex][param.passThis.columns[i].field] = "";
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    let _gridComboBoxNoRcdDispAfterReadDataComplete = function (param)
    {
        //after selected acc, read CCID completely only check its datasource is empty or not in order to display "No Records...".
        let rowIndex = param.grid.items().index(param.grid.select());

        if (rowIndex != -1)
        {
            let selectedItem = param.grid.dataSource.data()[rowIndex];

            let gotAcc;

            if (selectedItem.AccKey > 0)
                gotAcc = true;
            else
                gotAcc = false;

            if (gotAcc == false || (gotAcc == true && selectedItem.IsRequiredCCID == false))
            {

            }
            else
            {
                if (param.e.responseJSON.value.length == 0 && gotAcc == true && selectedItem.IsRequiredCCID == true)
                {
                    param.datasource._data[rowIndex]["CCIDCodeCCIDDesc"] = "No Records...";

                    param.grid.refresh();
                }
                else
                {
                    if (param.datasource._data[rowIndex]["CCIDCodeCCIDDesc"] == "No Records...")
                    {
                        param.datasource._data[rowIndex]["CCIDCodeCCIDDesc"] = "";

                        param.grid.refresh();
                    }
                }
            }
        }
    }

    let _disableInactiveItem = function (e, combobox)
    {
        //return true => prevent it
        //return false => proceed

        if (e !== undefined && combobox !== undefined)
        {
            if (e.item != null)
            {
                if (combobox.dataItem !== undefined)
                {
                    let dataItem = combobox.dataItem(e.item.index());
                    if (dataItem !== undefined && dataItem !== null)
                    {
                        if (dataItem.Active !== undefined)
                        {
                            if (dataItem.Active === false)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }

                        if (dataItem.IsControlAccount !== undefined)
                        {
                            if (dataItem.IsControlAccount === true)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }

                        if (dataItem.Status !== undefined)
                        {
                            if ($.inArray(dataItem.Status.toString(), ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) > -1)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }

                        if (dataItem.StatusID !== undefined)
                        {
                            //for staff and general worker setup use, disable Transferred selection
                            if ($.inArray(dataItem.StatusID.toString(), ["11", 11]) > -1)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }

                        //apply the tag
                        if (dataItem.setInactive !== undefined)
                        {
                            if (dataItem.setInactive === true)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    let _disableInactiveItem_EnableAll = function (e, combobox)
    {
        //return true => prevent it
        //return false => proceed

        if (e !== undefined && combobox !== undefined)
        {
            if (e.item != null)
            {
                if (combobox.dataItem !== undefined)
                {
                    let dataItem = combobox.dataItem(e.item.index());
                    if (dataItem !== undefined && dataItem !== null)
                    {
                        //apply the tag
                        if (dataItem.setInactive !== undefined)
                        {
                            if (dataItem.setInactive === true)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    let _disableInactiveItem_EnableInactive = function (e, combobox)
    {
        //return true => prevent it
        //return false => proceed

        if (e !== undefined && combobox !== undefined)
        {
            if (e.item != null)
            {
                if (combobox.dataItem !== undefined)
                {
                    let dataItem = combobox.dataItem(e.item.index());
                    if (dataItem !== undefined && dataItem !== null)
                    {
                        if (dataItem.IsControlAccount !== undefined)
                        {
                            if (dataItem.IsControlAccount === true)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }
                        
                        //apply the tag
                        if (dataItem.setInactive !== undefined)
                        {
                            if (dataItem.setInactive === true)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    let _disableInactiveItem_EnableCtrlAcc = function (e, combobox)
    {
        //return true => prevent it
        //return false => proceed

        if (e !== undefined && combobox !== undefined)
        {
            if (e.item != null)
            {
                if (combobox.dataItem !== undefined)
                {
                    let dataItem = combobox.dataItem(e.item.index());
                    if (dataItem !== undefined && dataItem !== null)
                    {
                        if (dataItem.Active !== undefined)
                        {
                            if (dataItem.Active === false)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }
                        
                        if (dataItem.Status !== undefined)
                        {
                            if ($.inArray(dataItem.Status.toString(), ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]) > -1)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }

                        if (dataItem.StatusID !== undefined)
                        {
                            //for staff and general worker setup use, disable Transferred selection
                            if ($.inArray(dataItem.StatusID.toString(), ["11", 11]) > -1)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }

                        //apply the tag
                        if (dataItem.setInactive !== undefined)
                        {
                            if (dataItem.setInactive === true)
                            {
                                e.preventDefault();

                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    let _comboboxItemListDisabledTemplate = function (valueColumn, dataColumn)
    {
        if (typeof dataColumn === "undefined")
        {
            if (Array.isArray(valueColumn))
            {
                let displayedItem = "";

                for (var i = 0; i < valueColumn.length; i++)
                {
                    displayedItem = displayedItem + "#: " + valueColumn[i] + " #";
                    if (valueColumn.length - 1 > i)
                    {
                        displayedItem = displayedItem + "-";
                    }

                    if (displayedItem !== "")
                    {
                        return kendo.template('<span class="#: (typeof setInactive!=\'undefined\' ? (setInactive==true) : (typeof Active!=\'undefined\' ? (typeof IsControlAccount!=\'undefined\' ? (Active==false || IsControlAccount==true) : (Active==false)) : (typeof IsControlAccount!=\'undefined\' ? (typeof Active!=\'undefined\' ? (Active==false || IsControlAccount==true) : (IsControlAccount==true)) : (typeof Status!=\'undefined\' ? ((Status==1||Status==2||Status==3||Status==4||Status==5||Status==6||Status==7||Status==8||Status==9||Status==10||Status==11) ? (Status!=1) : false) : (typeof StatusID!=\'undefined\' ? (StatusID==11) : false))))) ? "custom-ItemList-disable": ""#">' + displayedItem + '</span>')
                    }
                }
            }
            else
            {
                return kendo.template('<span class="#: (typeof setInactive!=\'undefined\' ? (setInactive==true) : (typeof Active!=\'undefined\' ? (typeof IsControlAccount!=\'undefined\' ? (Active==false || IsControlAccount==true) : (Active==false)) : (typeof IsControlAccount!=\'undefined\' ? (typeof Active!=\'undefined\' ? (Active==false || IsControlAccount==true) : (IsControlAccount==true)) : (typeof Status!=\'undefined\' ? ((Status==1||Status==2||Status==3||Status==4||Status==5||Status==6||Status==7||Status==8||Status==9||Status==10||Status==11) ? (Status!=1) : false) : (typeof StatusID!=\'undefined\' ? (StatusID==11) : false))))) ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
            }
        }
        else if (dataColumn.toString() === "Active")
        {
            return kendo.template('<span class="#: ' + dataColumn.toString() + '==false ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
        }
        else if (dataColumn.toString() === "Status")
        {
            return kendo.template('<span class="#: ' + dataColumn.toString() + '!=1 ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
        }
        else if (dataColumn.toString() === "StatusID")
        {
            //for staff and general worker setup use, disable Transferred selection
            return kendo.template('<span class="#: ' + dataColumn.toString() + '==11 ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
        }
    }

    let _comboboxItemListDisabledTemplate_EnableAll = function (valueColumn, dataColumn)
    {
        if (typeof dataColumn === "undefined")
        {
            if (Array.isArray(valueColumn))
            {
                let displayedItem = "";

                for (var i = 0; i < valueColumn.length; i++)
                {
                    displayedItem = displayedItem + "#: " + valueColumn[i] + " #";
                    if (valueColumn.length - 1 > i)
                    {
                        displayedItem = displayedItem + "-";
                    }

                    if (displayedItem !== "")
                    {
                        return kendo.template('<span class="#: (typeof setInactive!=\'undefined\' ? (setInactive==true) : (false)) ? "custom-ItemList-disable": ""#">' + displayedItem + '</span>')
                    }
                }
            }
            else
            {
                return kendo.template('<span class="#: (typeof setInactive!=\'undefined\' ? (setInactive==true) : (false)) ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
            }
        }
    }

    let _comboboxItemListDisabledTemplate_EnableInactive = function (valueColumn, dataColumn)
    {
        if (typeof dataColumn === "undefined")
        {
            if (Array.isArray(valueColumn))
            {
                let displayedItem = "";

                for (var i = 0; i < valueColumn.length; i++)
                {
                    displayedItem = displayedItem + "#: " + valueColumn[i] + " #";
                    if (valueColumn.length - 1 > i)
                    {
                        displayedItem = displayedItem + "-";
                    }

                    if (displayedItem !== "")
                    {
                        return kendo.template('<span class="#: (typeof setInactive!=\'undefined\' ? (setInactive==true) : (typeof IsControlAccount!=\'undefined\' ? (IsControlAccount==true) : (false))) ? "custom-ItemList-disable": ""#">' + displayedItem + '</span>')
                    }
                }
            }
            else
            {
                return kendo.template('<span class="#: (typeof setInactive!=\'undefined\' ? (setInactive==true) : (typeof IsControlAccount!=\'undefined\' ? (IsControlAccount==true) : (false))) ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
            }
        }
    }

    let _comboboxItemListDisabledTemplate_EnableCtrlAcc = function (valueColumn, dataColumn)
    {
        if (typeof dataColumn === "undefined")
        {
            if (Array.isArray(valueColumn))
            {
                let displayedItem = "";

                for (var i = 0; i < valueColumn.length; i++)
                {
                    displayedItem = displayedItem + "#: " + valueColumn[i] + " #";
                    if (valueColumn.length - 1 > i)
                    {
                        displayedItem = displayedItem + "-";
                    }

                    if (displayedItem !== "")
                    {
                        return kendo.template('<span class="#: (typeof setInactive!=\'undefined\' ? (setInactive==true) : (typeof Active!=\'undefined\' ? (Active==false) : (typeof Status!=\'undefined\' ? ((Status==1||Status==2||Status==3||Status==4||Status==5||Status==6||Status==7||Status==8||Status==9||Status==10||Status==11) ? (Status!=1) : false) : (typeof StatusID!=\'undefined\' ? (StatusID==11) : false)))) ? "custom-ItemList-disable": ""#">' + displayedItem + '</span>')
                    }
                }
            }
            else
            {
                return kendo.template('<span class="#: (typeof setInactive!=\'undefined\' ? (setInactive==true) : (typeof Active!=\'undefined\' ? (Active==false) : (typeof Status!=\'undefined\' ? ((Status==1||Status==2||Status==3||Status==4||Status==5||Status==6||Status==7||Status==8||Status==9||Status==10||Status==11) ? (Status!=1) : false) : (typeof StatusID!=\'undefined\' ? (StatusID==11) : false)))) ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
            }
        }
        else if (dataColumn.toString() === "Active")
        {
            return kendo.template('<span class="#: ' + dataColumn.toString() + '==false ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
        }
        else if (dataColumn.toString() === "Status")
        {
            return kendo.template('<span class="#: ' + dataColumn.toString() + '!=1 ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
        }
        else if (dataColumn.toString() === "StatusID")
        {
            //for staff and general worker setup use, disable Transferred selection
            return kendo.template('<span class="#: ' + dataColumn.toString() + '==11 ? "custom-ItemList-disable": ""#">#: ' + valueColumn.toString() + ' #</span>')
        }
    }

    let _exportGridToExcel = function (filename, displayGrid)
    {
        try
        {
            let temp = filename.toString();
            filename = temp;
        }
        catch (ex)
        {
            filename = "";
        }

        //overwrite certain thing, set third parameter to true
        excelExportService.exportToExcel(displayGrid, filename, true);
    };

    let _OpContains = function (item, value)
    {
        let found = false;
        let numSubStrSearch = false;

        if (typeof item !== "undefined")
        {
            let itemList = [];

            if (typeof item === "string")
            {
                itemList = item.split(",");
            }
            else if (typeof item === "object")
            {
                itemList = item;
            }
            else if (typeof item === "number" || typeof item === "decimal") //to cater decimal or number with contain
            {
                itemList.push(item);
                numSubStrSearch = true;
            }
            else
            {
                //i dont accept any other type to use this function!
                return false;
            }

            let itemLength = itemList.length;
            for (var i = 0; i < itemLength; i++)
            {
                if (typeof itemList[i] !== "undefined" && typeof value !== "undefined" && itemList[i] !== null && value !== null)
                {
                    if (numSubStrSearch === false)
                    {
                        if (itemList[i].toString() === value.toString())
                        {
                            found = true;
                            break;
                        }
                    }
                    else
                    {
                        try
                        {
                            //compare both part, integer and decimal part
                            let sourceInt = Math.floor(itemList[i]);
                            let sourceDecimal = itemList[i].Nminus(sourceInt);

                            let targetInt = 0;
                            let targetDecimal = 0;

                            if (value.toString().startsWith("."))
                            {
                                targetInt = 0;
                                targetDecimal = ("0" + value.toString()).Nminus(0);
                            }
                            else
                            {
                                targetInt = Math.floor(value.toString().Nminus(0));
                                targetDecimal = value.toString().Nminus(targetInt);
                            }

                            let decimalpartMatches = (sourceDecimal.toString().indexOf(targetDecimal.toString()) > -1);
                            let integerpartMatches = false;

                            if (value.toString().startsWith("."))
                            {
                                integerpartMatches = true;
                            }
                            else
                            {
                                if (sourceInt.toString().endsWith(targetInt.toString()))
                                {
                                    integerpartMatches = true;
                                }
                            }

                            if (decimalpartMatches && integerpartMatches)
                            {
                                found = true;
                                break;
                            }
                        }
                        catch (ex)
                        {
                            //ignore, high chance value is not number
                        }
                    }
                }
            }
        }

        return found;
    }

    let _refreshGridWithLockedColumn = function (grid, options)
    {
        for (var i = 0; i < options.columns.length; i++)
        {
            options.columns[i].width = options.columns[i].width;
        };
        grid.setOptions(options);
        return true;
    }

    let _forceAlphaNumeric = function (event)
    {
        if (typeof event.type === "string")
        {
            if (event.type === "paste")
            {
                let pastedtext = event.originalEvent.clipboardData.getData('Text');

                if (typeof pastedtext === "string")
                {
                    let regex = new RegExp("^[a-zA-Z0-9]+$");

                    if (!regex.test(pastedtext))
                    {
                        event.preventDefault();
                        return false;
                    }
                }
            }
            else if (event.type === "keypress")
            {
                if (typeof event.key === "string")
                {
                    let regex = new RegExp("^[a-zA-Z0-9]+$");

                    if (!regex.test(event.key))
                    {
                        event.preventDefault();
                        return false;
                    }
                }
            }
        }
    }

    let _forceNumeric = function (event)
    {
        if (typeof event.type === "string")
        {
            if (event.type === "paste")
            {
                let pastedtext = event.originalEvent.clipboardData.getData('Text');

                if (typeof pastedtext === "string")
                {
                    let regex = new RegExp("^[0-9]+$");

                    if (!regex.test(pastedtext))
                    {
                        event.preventDefault();
                        return false;
                    }
                }
            }
            else if (event.type === "keypress")
            {
                if (typeof event.key === "string")
                {
                    let regex = new RegExp("^[0-9]+$");

                    if (!regex.test(event.key))
                    {
                        event.preventDefault();
                        return false;
                    }
                }
            }
        }
    }

    controlServiceFactory.requestForm = managementService.requestForm;
    controlServiceFactory.requestFormFromFormInformation = _requestFormFromFormInformation;
    controlServiceFactory.forceAlphaNumeric = _forceAlphaNumeric;
    controlServiceFactory.forceNumeric = _forceNumeric;
    controlServiceFactory.initTabControl = managementService.initializeController;
    controlServiceFactory.initReportTabControl = managementService.initializeReportController;
    controlServiceFactory.setPrintingFunction = _setPrintingFunction;
    controlServiceFactory.setPrintingWithParam = _setPrintingWithParam;
    controlServiceFactory.checkLimit = _checkLimit;
    controlServiceFactory.scrollTop = _scrollTop;
    controlServiceFactory.backToTop = _backToTop;
    controlServiceFactory.parseUrl = _parseUrl;
    controlServiceFactory.clearDetailBind = function () { /*this is useless aready*/ };
    controlServiceFactory.assignDetailBind = function () { /*this is useless aready*/ };
    controlServiceFactory.setFullScreen = _setFullScreen;
    controlServiceFactory.sendEncryptedDataToServer = _sendEncryptedDataToServer;
    controlServiceFactory.numericTextboxEditor = _numericTextboxEditor;
    controlServiceFactory.numericTextboxMaxlength1Editor = _numericTextboxMaxlength1Editor;
    controlServiceFactory.numericTextboxMaxlength6Editor = _numericTextboxMaxlength6Editor;
    controlServiceFactory.numericTextboxMaxlength7Editor = _numericTextboxMaxlength7Editor;
    controlServiceFactory.numericTextboxMaxlength14Editor = _numericTextboxMaxlength14Editor;
    controlServiceFactory.numericTextboxMaxlength14AllowLessThan0Editor = _numericTextboxMaxlength14AllowLessThan0Editor;
    controlServiceFactory.numericTextboxMaxlength14AllowNullEditor = _numericTextboxMaxlength14AllowNullEditor;

    controlServiceFactory.nmComboBoxNoRcdDisp = _nmComboBoxNoRcdDisp;
    controlServiceFactory.gridComboBoxNoDataDisableCell = _gridComboBoxNoDataDisableCell;
    controlServiceFactory.gridComboBoxNoRcdDisp = _gridComboBoxNoRcdDisp;
    controlServiceFactory.gridComboBoxNoRcdDispAfterReadDataComplete = _gridComboBoxNoRcdDispAfterReadDataComplete;

    controlServiceFactory.comboBoxOpenEvent = _comboBoxOpenEvent;
    controlServiceFactory.comboBoxOpenEvent_EnableAll = _comboBoxOpenEvent_EnableAll;
    controlServiceFactory.comboBoxOpenEvent_EnableInactive = _comboBoxOpenEvent_EnableInactive;
    controlServiceFactory.comboBoxOpenEvent_EnableCtrlAcc = _comboBoxOpenEvent_EnableCtrlAcc;

    controlServiceFactory.disableInactiveItem = _disableInactiveItem;
    controlServiceFactory.disableInactiveItem_EnableAll = _disableInactiveItem_EnableAll;
    controlServiceFactory.disableInactiveItem_EnableInactive = _disableInactiveItem_EnableInactive;
    controlServiceFactory.disableInactiveItem_EnableCtrlAcc = _disableInactiveItem_EnableCtrlAcc;

    controlServiceFactory.comboboxItemListDisabledTemplate = _comboboxItemListDisabledTemplate;
    controlServiceFactory.comboboxItemListDisabledTemplate_EnableAll = _comboboxItemListDisabledTemplate_EnableAll;
    controlServiceFactory.comboboxItemListDisabledTemplate_EnableInactive = _comboboxItemListDisabledTemplate_EnableInactive;
    controlServiceFactory.comboboxItemListDisabledTemplate_EnableCtrlAcc = _comboboxItemListDisabledTemplate_EnableCtrlAcc;

    controlServiceFactory.exportGridToExcel = _exportGridToExcel;

    controlServiceFactory.OpContains = _OpContains;
    controlServiceFactory.refreshGridWithLockedColumn = _refreshGridWithLockedColumn;
    controlServiceFactory.viewKnowledgeBase = _viewKnowledgeBase;

    return controlServiceFactory;
}]);